import "./ContactedProperty.css";
import { React, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart as fasolidHeart,
  faLocationDot,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart,
  faShareFromSquare,
} from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useEffect } from "react";
import CustomCarousel from "./Others/CustomCarousel";
const ListedProperty = ({ user }) => {
  const [listedProperties, setlistedProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleShareClick = async (title, url) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: title,
          url: url,
        });
      } else {
        console.log("Web Share API is not supported.");
      }
    } catch (error) {
      // console.error("Error sharing:", error);
    }
  };
  const toggleBookmark = async (id) => {
    if (!user) {
      alert("Login to save property");
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_IP}/user/saved`,
          {
            method: "POST",
            body: JSON.stringify({ id: user.id, propertyId: id }), // Convert object to JSON string
            headers: {
              "Content-Type": "application/json", // Set the correct content type for JSON
            },
          }
        );
        if (response.ok) {
          window.location.reload();
        } else {
          console.error("Failed to submit property:", response.status);
        }
      } catch (error) {
        console.error("Error submitting property:", error);
      }
    }
  };
  useEffect(() => {
    if (user) {
      setIsLoading(true);
      try {
        fetch(
          `${process.env.REACT_APP_API_IP}/property/${user.id}/ListedProperties/vfijhvuihbv`
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error("Network response was not ok");
            }
            return res.json();
          })
          .then((data) => {
            setlistedProperties(data);
            setIsLoading(false);
          })
          .catch((err) => {
            // console.log(err);
            setIsLoading(false);
          });
      } catch (error) {
        // console.log(error);
      }
    }
  }, [user]);
  const [currentPage, setCurrentPage] = useState(1);
  let totalPages = 1;
  let visibleData = [];
  visibleData = listedProperties;
  if (listedProperties && listedProperties.length > 10) {
    totalPages = Math.ceil(listedProperties.length / 10);
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    visibleData = listedProperties.slice(startIndex, endIndex);
  }
  const handleToggle = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_IP}/property/toggleStatus/${id}`
    );
    if (response.ok) {
      window.location.reload();
    } else {
      // console.log(response);
    }
  };
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo(0, 0);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo(0, 0);
  };
  return (
    <div className="MainContainer">
      <div className="contactedPageMain ">
        <div id="contactedPropertyHeading">
          <h4 className="mt-5">Listed Properties :</h4>
        </div>
        <div className="mb-3 " style={{ maxWidth: "100%" }}>
          {visibleData.length === 0 ? (
            isLoading ? ( // Check if data is loading
              <div className="text-center fs-4">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="text-center fs-2 mb-5">No Result Found</div>
            )
          ) : (
            visibleData.map((property, index) => (
              <div
                className="row shadow-sm no-gutters rounded-2"
                key={index}
                id="propertyCard"
              >
                <div className="col-md-4 col-lg-4 p-0">
                  <div id="GenderBox">
                    <span className="">
                      {property.memberedAllowed === "male"
                        ? "Male"
                        : property.memberedAllowed === "female"
                        ? "Female"
                        : property.memberedAllowed === "both"
                        ? "Both"
                        : property.memberedAllowed}
                    </span>
                  </div>
                  <CustomCarousel photoUrls={property.photoUrls} />
                  <div id="iconBox">
                    <span
                      className={`bookmark-button ShareIcons heart ${
                        property.isSaved ? "bookmarked" : ""
                      }`}
                      onClick={() => {
                        toggleBookmark(property.id);
                      }}
                    >
                      {property.isSaved ? (
                        <FontAwesomeIcon icon={fasolidHeart} />
                      ) : (
                        <FontAwesomeIcon icon={faHeart} />
                      )}
                    </span>
                    <span
                      className="ShareIcons"
                      style={{ zIndex: "2" }}
                      onClick={() => {
                        handleShareClick(
                          property.title,
                          `/particular-property/${property.id}`
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faShareFromSquare} />
                    </span>
                  </div>
                </div>
                <div className="col-md-8 col-lg-8" id="propertyCard-body">
                  <Link
                    className="text-decoration-none text-dark"
                    to={`/particular-property/${property.id}`}
                  >
                    <div id="card-Heading">{property.title}</div>
                    <div id="card-location" className="row">
                      <div id="" className="col">
                        <FontAwesomeIcon
                          className="me-2 grey"
                          icon={faLocationDot}
                        />{" "}
                        {property.address.area.charAt(0).toUpperCase() +
                          property.address.area.slice(1) +
                          " "}
                        {property.address.city} {property.address.pincode}
                      </div>
                      <div className="LaptopHide col">
                        Type :{" "}
                        {property.furnishedType === "fully-furnished"
                          ? "Fully-Furnished"
                          : property.furnishedType === "unfurnished"
                          ? "Unfurnished"
                          : property.furnishedType === "semi-furnished"
                          ? "Semi-Furnished"
                          : property.furnishedType}
                      </div>
                    </div>
                    <div id="card-Details" className="row">
                      <div id="Details" className="col">
                        <span className="grey">Available For :</span>{" "}
                        {property.memberedAllowed === "male"
                          ? "Male"
                          : property.memberedAllowed === "female"
                          ? "Female"
                          : property.memberedAllowed === "both"
                          ? "Both"
                          : property.memberedAllowed}
                      </div>
                      <div id="Details" className="col">
                        <span className="grey">Property Type :</span>{" "}
                        {property.subtype.length > 12
                          ? `${property.subtype.substring(0, 12)}..`
                          : property.subtype}
                      </div>
                    </div>
                    <div id="card-Details" className="row">
                      <div id="Details" className="col">
                        <span className="grey">Property Size :</span>{" "}
                        {property.bedroom}
                      </div>
                      <div id="Details" className="col">
                        <span className="grey">Status :</span>
                        <span
                          style={{
                            color: property.isRentedout === 0 ? "green" : "red",
                          }}
                        >
                          {property.isRentedout === 0
                            ? "Available"
                            : "Rented Out"}
                        </span>{" "}
                      </div>
                    </div>
                    <div id="emnities" className=" mobileAndTab-hide">
                      {property.amenities.includes("electricity") && (
                        <img
                          className="amenities-size"
                          src="/emenities/Electricity.png"
                          alt="Amenities"
                        />
                      )}
                      {property.amenities.includes("cctv") && (
                        <img
                          className=" amenities-size"
                          src="/emenities/CCTV.png"
                          alt="Amenities"
                        />
                      )}
                      {property.amenities.includes("cooking") && (
                        <img
                          className=" amenities-size"
                          src="/emenities/Cooking.png"
                          alt="Amenities"
                        />
                      )}
                      {property.amenities.includes("24*7-water") && (
                        <img
                          className=" amenities-size"
                          src="/emenities/Water.png"
                          alt="Amenities"
                          style={{ color: "red" }}
                        />
                      )}
                      {property.amenities.includes("house-keeping") && (
                        <img
                          className=" amenities-size"
                          src="/emenities/Housekeeping.png"
                          alt="Amenities"
                          style={{ color: "red" }}
                        />
                      )}
                      {property.amenities.includes("2-wheeler-parking") && (
                        <img
                          className=" amenities-size"
                          src="/emenities/Parking.png"
                          alt="Amenities"
                          style={{ color: "red" }}
                        />
                      )}
                      {property.amenities.includes("fans") && (
                        <img
                          className=" amenities-size"
                          src="/emenities/Fen.png"
                          alt="Amenities"
                          style={{ color: "red" }}
                        />
                      )}
                      {property.amenities.includes("fridge") && (
                        <img
                          className=" amenities-size"
                          src="/emenities/Fridge.png"
                          alt="Amenities"
                          style={{ color: "red" }}
                        />
                      )}
                      {property.amenities.includes("laundry") && (
                        <img
                          className=" amenities-size"
                          src="/emenities/Laundry.png"
                          alt="Amenities"
                          style={{ color: "red" }}
                        />
                      )}
                      {property.amenities.includes("security") && (
                        <img
                          className=" amenities-size"
                          src="/emenities/Security.png"
                          alt="Amenities"
                          style={{ color: "red" }}
                        />
                      )}
                    </div>
                    <div id="rentBox" className="row">
                      <div className="col p-0 mobileAndTab-hide">
                        <b
                          style={{
                            fontSize: "23px",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          {property.type === "private flat"
                            ? `₹ ${property.totalFlatRent}`
                            : property.sharingType.singleRent
                            ? `₹ ${property.sharingType.singleRent}`
                            : property.sharingType.doubleRent
                            ? `₹ ${property.sharingType.doubleRent}`
                            : property.sharingType.tripleRent
                            ? `₹ ${property.sharingType.tripleRent}`
                            : `₹ ${property.rentEachHead}`}
                        </b>{" "}
                        <span className="grey">/per month</span>
                      </div>
                      <div
                        className="col p-0 mobileAndTab-hide mt-2"
                        style={{ color: "rgba(0, 0, 0, 0.7)" }}
                      >
                        {" "}
                        <span className="grey">Deposit </span>:{" "}
                        {property.deposit} Rent{" "}
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="rounded-4 " id="bottomBar">
                  <div className="LaptopHide">
                    <b
                      style={{
                        marginLeft: "2px",
                        fontSize: "20px",
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      {property.type === "private flat"
                        ? `₹ ${property.totalFlatRent}`
                        : property.sharingType.singleRent
                        ? `₹ ${property.sharingType.singleRent}`
                        : property.sharingType.doubleRent
                        ? `₹ ${property.sharingType.doubleRent}`
                        : property.sharingType.tripleRent
                        ? `₹ ${property.sharingType.tripleRent}`
                        : `₹ ${property.rentEachHead}`}{" "}
                    </b>{" "}
                    <span className="grey" style={{ fontSize: "12px" }}>
                      /per month
                    </span>
                  </div>
                  <div className="md-fs-5 col-lg-4">
                    Property Status :{" "}
                    {property.isVerified ? (
                      !property.isRentedout ? (
                        <span className="text-success fw-bold">Live</span>
                      ) : (
                        <span className="text-danger fw-bold">Rent Out</span>
                      )
                    ) : (
                      <span className="text-warning fw-bold">Under Review</span>
                    )}
                  </div>
                  <div className="toggle-container col-lg-4">
                    <p className="toggle-text ms-0">Available</p>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={property.isRentedout}
                        onChange={() => {
                          handleToggle(property.id);
                        }}
                      />
                      <span className="slider"></span>
                    </label>
                    <p className="toggle-text">Rent Out</p>
                  </div>
                  <div className="grey col-lg-4">
                    To edit listing :{" "}
                    <Link to={"https://wa.me/9316066832"} className="">
                      <button className="btn btn-outline-primary rounded-pill">
                        Contact Us
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          )}
          {!isLoading && listedProperties.length > 10 && (
            <div className="paginationBox">
              <Button className="paginationBtn" onClick={goToPreviousPage}>
                {" "}
                <FontAwesomeIcon icon={faArrowLeft} /> Previous{" "}
              </Button>
              <Button className="paginationBtn" onClick={goToNextPage}>
                Next <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListedProperty;
