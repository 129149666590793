import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faCircleXmark,
  faClose,
  faPhone,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Navigate, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Button, FormGroup, Input, Label } from "reactstrap";
import axios from "axios";
import imageCompression from "browser-image-compression";
import getMobileOS from "../../../helpers/os";

const Step4 = ({
  propertyDetails,
  updatePropertyDetails,
  currentStep,
  setCurrentStep,
}) => {
  const [address, setAddress] = useState({
    houseno: "",
    area: "",
    pincode: "",
    city: "",
    streetAddress: "",
  });
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const [photoLoading, setPhotoLoading] = useState([]);
  const [thankyoumodal, setThankyoumodal] = useState(false);
  const os = getMobileOS();
  const Navigate = useNavigate();
  const thankyouModal = () => {
    if (success === "Error") {
      setThankyoumodal(false);
    } else {
      if (os === "Android") {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.codingislife.citynect";
      } else if (os === "iOS") {
        window.location.href =
          "https://apps.apple.com/in/app/citynect/id6503302420?platform=iphone";
      } else {
        Navigate("/myaccount/listedproperty");
      }
    }
  };

  const onDrop = async (acceptedFiles) => {
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const validVideoTypes = ["video/mp4"];
    const newErrors = [];

    if (acceptedFiles.length > 10) {
      newErrors.push({
        name: "photos",
        error: "You can upload a maximum of 10 files at a time.",
      });
      setErrors(newErrors);
      return;
    }

    const validFiles = acceptedFiles.filter((file) => {
      if (
        !validImageTypes.includes(file.type) &&
        !validVideoTypes.includes(file.type)
      ) {
        newErrors.push({
          name: "photos",
          error: "Please upload JPG, PNG, or MP4 only.",
        });
        return false;
      }
      return true;
    });

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return; // Return early if there are errors
    } else {
      setErrors([]); // Clear previous photo errors
    }

    // Ensure total number of photos including previously uploaded ones does not exceed 10
    if (acceptedFiles.length + photos.length > 10) {
      newErrors.push({
        name: "photos",
        error: "You can upload a maximum of 10 files at a time.",
      });
      setErrors(newErrors);
      return;
    }

    setPhotoLoading(Array(validFiles.length).fill(true)); // Set loading state for each photo

    await Promise.all(
      validFiles.map(async (file, index) => {
        try {
          if (validImageTypes.includes(file.type)) {
            const compressedFile = await compressImage(file);
            setPhotos((prevPhotos) => [...prevPhotos, compressedFile]);
          } else {
            setPhotos((prevPhotos) => [...prevPhotos, file]);
          }
        } catch (error) {
          // console.error("Error processing file:", error);
          // Handle error, if needed
        } finally {
          setPhotoLoading((prev) => {
            const newLoadingState = [...prev];
            newLoadingState[index] = false;
            return newLoadingState;
          });
        }
      })
    );
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      // console.error("Image compression error:", error);
      return null;
    }
  };

  const handleBlur = (e) => {
    handleAddressChange(e.target.name, e.target.value);
  };

  const handleAddressChange = (field, value) => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));

    const updatedAddress = {
      ...propertyDetails,
      address: { ...address, [field]: value },
    };
    updatePropertyDetails(updatedAddress);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 10,
  });

  const handleDeletePhoto = (index) => {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
  };

  const handleSubmit = async () => {
    const isValid = validateForm();
    // console.log(isValid);
    if (isValid) {
      setLoading(true);
      setThankyoumodal(true);
      try {
        // await makeAddress()
        const formData = new FormData();
        formData.append(
          "property",
          JSON.stringify({
            ownerId: propertyDetails.ownerId,
            name: propertyDetails.name,
            phone: propertyDetails.phone,
            tenantNumber: propertyDetails.tenantNumber,
            type: propertyDetails.type,
            subtype: propertyDetails.subtype,
            bedroom: propertyDetails.bedroom,
            furnishedType: propertyDetails.furnishedType,
            amenities: propertyDetails.amenities,
            memberedAllowed: propertyDetails.memberedAllowed,
            nonVegAllowed: propertyDetails.nonVegAllowed,
            instructions: propertyDetails.instructions,
            availableFrom: propertyDetails.availableFrom,
            washroomAttached: propertyDetails.washroomAttached,
            preferenceTypes: propertyDetails.preference,
            sharingType: {
              singleSharing: propertyDetails.sharingType.singleSharing,
              singleRent: propertyDetails.rentEachHead.single,
              doubleSharing: propertyDetails.sharingType.doubleSharing,
              doubleRent: propertyDetails.rentEachHead.double,
              tripleSharing: propertyDetails.sharingType.tripleSharing,
              tripleRent: propertyDetails.rentEachHead.triple,
            },
            totalFlatRent: propertyDetails.totalFlatRent,
            isNegotiable: propertyDetails.isNegotiable,
            deposit: propertyDetails.deposit,
            noticePeriond: propertyDetails.noticePeriod,
            address: propertyDetails.address,
            description: propertyDetails.description,
            callAllowed: propertyDetails.callAllowed,
          })
        );
        photos.forEach((image) => {
          formData.append("photos", image);
        });
        const response = await axios.post(
          `${process.env.REACT_APP_API_IP}/property/v2/list-property/add`,
          formData
        );
        // console.log(response);
        if (response.status === 200) {
          setLoading(false);
          setSuccess(true);
        } else {
          setSuccess("Error");
          setLoading(false);
          // console.error("Failed to submit property:", response.status);
        }
      } catch (error) {
        setLoading(false);
        setSuccess("Error");
        // console.error("Error submitting property:", error);
      }
    }
  };

  const validateForm = () => {
    const newErrors = [];

    if (!address.streetAddress) {
      newErrors.push({
        name: "streetAddress",
        error: "Please enter the street address.",
      });
    }
    if (!address.houseno) {
      newErrors.push({
        name: "houseno",
        error: "Please enter the society name.",
      });
    }
    if (!address.pincode) {
      newErrors.push({ name: "pincode", error: "Please enter the pincode." });
    }
    if (!address.city) {
      newErrors.push({ name: "city", error: "Please enter the city." });
    }
    if (!address.area) {
      newErrors.push({ name: "area", error: "Please enter the area." });
    }

    if (photos.length < 1) {
      newErrors.push({
        name: "photos",
        error: "Please upload at least three photo.",
      });
    } else if (photos.length > 10) {
      newErrors.push({
        name: "photos",
        error: "Please upload a maximum of ten photos.",
      });
    }

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSelect = (field, fullAddress, place) => {
    if (fullAddress) {
      const splitAddress = fullAddress.split(",")[0]; // Take only the first part before comma
      const pinCodeMatch = splitAddress.match(/\b\d{6}\b/);
      const zipCode = pinCodeMatch ? pinCodeMatch[0] : null;
      let city =
        place?.address_components?.find((component) =>
          component.types.includes("locality")
        )?.long_name || "";

      if (city.includes(",")) {
        city = city.split(",")[0]; // Take only the first part before comma for city
      }

      // Trim the city to only the first word
      city = city.split(" ")[0];

      setAddress((prevAddress) => ({
        ...prevAddress,
        [field]: splitAddress,
        ...(field === "streetAddress" && { pincode: zipCode, city: city }),
      }));
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initAutocomplete`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    window.initAutocomplete = () => {
      const inputs = ["houseno", "streetAddress", "area"];
      inputs.forEach((input) => {
        const inputElement = document.getElementById(input);
        const autocomplete = new window.google.maps.places.Autocomplete(
          inputElement,
          {
            types: ["geocode"],
            componentRestrictions: { country: "IN" },
          }
        );

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          handleSelect(input, place.formatted_address, place);
        });
      });
    };

    return () => {
      window.initAutocomplete = null;
    };
  }, []);
// console.log(propertyDetails)
  return (
    <div className="container row" id="step2">
      <div className="col-md-4 col-lg-3">
        <div className="step-navigation" id="progressBar">
          <div className={"step completed"}>
            <span className="step-number completed">✔</span>
            Basic Details
          </div>
          <div className="d-flex">
            <div className="connector completed"></div>
            <small
              style={{
                color: "#000000a2",
                marginLeft: "10px",
                fontSize: "13px",
              }}
            >
              Step 1
            </small>
          </div>
          <div className={"step completed"}>
            <span className="step-number completed">✔</span>
            Property Profile
          </div>
          <div className="d-flex">
            <div className="connector completed"></div>
            <small
              style={{
                color: "#000000a2",
                marginLeft: "10px",
                fontSize: "13px",
              }}
            >
              Step 2
            </small>
          </div>
          <div className="step active">
            <span className="step-number active"></span>
            Photos
          </div>
        </div>
        <div className="mt-3" id="needHelp">
          <h2>Need Help ?</h2>
          <h6 style={{ opacity: "0.8" }}>Contact us Now</h6>
          <span>
            <FontAwesomeIcon icon={faPhone} /> +91-9316066832{" "}
          </span>
        </div>
      </div>
      <div className="col-md-6 col-lg-5" id="middle">
        <div
          role="button"
          id="backDiv"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span> Back</span>
        </div>
        <div className="mt-4" id="photosForm">
          <Label for="photos">Photos and Videos</Label>
          <p>Upload at least three photos or videos.</p>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div className="d-flex justify-content-center align-items-center">
              <div className="dragDropBox">
                <p className="dragDropText">Drag & drop photos/videos here</p>
                <p>or</p>
                <Button color="primary">Browse Files</Button>
                <p>(Maximum 10 files)</p>
              </div>
            </div>
          </div>
          {errors.find((error) => error.name === "photos") && (
            <Form.Text className="text-danger">
              {errors.find((error) => error.name === "photos").error}
            </Form.Text>
          )}

          <div className="row mt-4 step-4-photos">
            {photos.map((file, index) => (
              <div className="col-md-3 mb-4" key={index}>
                <div className="photoPreview">
                  {photoLoading[index] ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <>
                      <button
                        type="button"
                        className="deletePhotoButton"
                        onClick={() => handleDeletePhoto(index)}
                      >
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={{ background: "white", borderRadius: "50%" }}
                        />
                      </button>
                      {file.type.startsWith("video/") ? (
                        <video width="100%" controls>
                          <source
                            src={URL.createObjectURL(file)}
                            type={file.type}
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`preview-${index}`}
                          className="img-fluid"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div id="photosForm">
          <h5 id="photosFormHeader " className="mt-2">
            Fill Address Details
          </h5>
          <div className="d-flex mt-3">
            <FormGroup className="w-50 pe-3">
              <Label for="houseno">
                Society Name
                <span style={{ color: "#f56a6a" }}>*</span>
              </Label>
              <Input
                type="text"
                name="houseno"
                id="houseno"
                value={address.houseno}
                onChange={(e) => handleAddressChange("houseno", e.target.value)}
                onBlur={handleBlur}
                placeholder="Society Name"
              />
              {errors.find((error) => error.name === "houseno") && (
                <Form.Text className="text-danger">
                  {errors.find((error) => error.name === "houseno").error}
                </Form.Text>
              )}
            </FormGroup>
            <FormGroup className="w-50">
              <Label for="streetAddress">
                Street Address
                <span style={{ color: "#f56a6a" }}>*</span>
              </Label>
              <Input
                type="text"
                name="streetAddress"
                id="streetAddress"
                value={address.streetAddress}
                onChange={(e) =>
                  handleAddressChange("streetAddress", e.target.value)
                }
                onBlur={handleBlur}
                placeholder="Street Address"
              />
              {errors.find((error) => error.name === "streetAddress") && (
                <Form.Text className="text-danger">
                  {errors.find((error) => error.name === "streetAddress").error}
                </Form.Text>
              )}
            </FormGroup>
          </div>
          <div className="d-flex">
            <FormGroup className="w-50 pe-3">
              <Label for="area">
                Area
                <span style={{ color: "#f56a6a" }}>*</span>
              </Label>
              <Input
                type="text"
                name="area"
                id="area"
                value={address.area}
                onChange={(e) => handleAddressChange("area", e.target.value)}
                onBlur={handleBlur}
                placeholder="Area"
              />
              {errors.find((error) => error.name === "area") && (
                <Form.Text className="text-danger">
                  {errors.find((error) => error.name === "area").error}
                </Form.Text>
              )}
            </FormGroup>
            <FormGroup className="w-50">
              <Label for="pincode">
                Pincode
                <span style={{ color: "#f56a6a" }}>*</span>
              </Label>
              <Input
                type="text"
                name="pincode"
                id="pincode"
                value={address.pincode}
                onChange={(e) => handleAddressChange("pincode", e.target.value)}
                onBlur={handleBlur}
                placeholder="Pincode"
              />
              {errors.find((error) => error.name === "pincode") && (
                <Form.Text className="text-danger">
                  {errors.find((error) => error.name === "pincode").error}
                </Form.Text>
              )}
            </FormGroup>
          </div>
          <FormGroup className="w-50">
            <Label for="city">
              City
              <span style={{ color: "#f56a6a" }}>*</span>
            </Label>
            <Input
              type="text"
              name="city"
              id="city"
              value={address.city}
              onChange={(e) => handleAddressChange("city", e.target.value)}
              onBlur={handleBlur}
              placeholder="City"
            />
            {errors.find((error) => error.name === "city") && (
              <Form.Text className="text-danger">
                {errors.find((error) => error.name === "city").error}
              </Form.Text>
            )}
          </FormGroup>
        </div>
        <Button color="primary" onClick={handleSubmit} disabled={loading}>
          {loading ? <Spinner size="sm" /> : "Submit"}
        </Button>
      </div>
      <div className={`login-modal ${thankyoumodal ? "open" : ""}`}>
        <div className="modal-content">
          <Form>
            <div className="titleBox d-flex flex-column">
              <div className="closeBox">
                <span className="close" onClick={thankyouModal}>
                  <FontAwesomeIcon icon={faClose} />
                </span>
              </div>
            </div>
            {!loading ? (
              <div className="p-4">
                <div className="d-flex justify-content-center">
                  {success === true ? (
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ fontSize: "50px", color: "#287DFD" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      style={{ fontSize: "50px" }}
                    />
                  )}
                </div>
                <div className="subheading text-center fs-5 mt-3">
                  {success === true
                    ? `Thank you for adding your Requirements! Your post is now under admin approval for security and authenticity purposes. It will be live within one hour. Stay tuned!`
                    : "Internal Server Error Please try again later"}
                </div>
                <button className="mt-4" type="button" onClick={thankyouModal}>
                  Continue
                </button>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: "50px",
                }}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Step4;
