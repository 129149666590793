import React, { useState } from "react";
import ".././Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Form } from "reactstrap";
const ExpirePopup = ({ isOpen, onClose }) => {
  return (
    <div className={`login-modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <Form className="expire-model">
          <div className="titleBox d-flex flex-column">
            <div className="closeBox">
              <span className="expire-close" onClick={onClose}>
                <FontAwesomeIcon icon={faClose} />
              </span>
            </div>
            <div className="d-flex justify-content-center expire-heading">
              <p>Unlock Access</p>
            </div>
            <div style={{ position: "relative" , left : "8px"}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="88"
                height="65"
                viewBox="0 0 120 85"
                fill="none"
              >
                <path
                  d="M1.63663 64L44 0.897049L86.3634 64H1.63663Z"
                  fill="black"
                  fillOpacity="0.03"
                  stroke="white"
                />
              </svg>
              <p className="expire-description">!</p>
              <h2 className="text-white">Go Premium</h2>
            </div>

            <div className="text-center">
              <p className="mx-5 text-white mt-3">
               Subscribe to our pre mium plan to unlock owner details.
              </p>
              <div className="mb-5 mt-2">
                <a
                  href="/premium"
                  style={{ textDecoration: "none" }}
                  className="btn-own10"
                >
                  See Plans
                </a>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ExpirePopup;
