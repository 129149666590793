import React from 'react';
import "./CitySelectionModal.css";

const CitySelectionModal = ({ cities, onClose, onSelectCity, selectedCity, isMobileView,handleLocationChange,cityFilter }) => {

  
  return (
    <div className={`city-selection-modal ${isMobileView ? 'mobile-view' : ''}`}>
      <div className="city-selection-content">
        <div className="city-selection-header">
          <h2>Select a City</h2>
          <button onClick={onClose} className="close-modal">✕</button>
        </div>
        <div className="city-grid">
          {cityFilter.map((Button,INDEX) => {            
            return(
            <div
              key={INDEX}
              className={`city-item ${selectedCity === Button.cityName ? 'selected' : ''}`}
              onClick={() =>{ handleLocationChange(Button.cityName) 
                onSelectCity(Button.cityName)}
              }
            >
              <img src={Button.imageUrl} alt={Button.cityName} className="city-image" />
              <p>{Button.cityName}</p>
            </div>
          )})}
        </div>
      </div>
    </div>
  );
};

export default CitySelectionModal;
