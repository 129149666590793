import {
  faHeart as fasolidHeart,
  faEllipsisVertical,
  faLocationDot,
  faAngleDown,
  faPhone,
  faClose,
  faCrown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart,
  faShareFromSquare,
} from "@fortawesome/free-regular-svg-icons";
import LoginModal from "./Login.js";
// import CustomPopup from "./Others/CustomPopup.js";
// import CustomWhatsappPopup from "./Others/CustomWhatsappPopup.js";
import ExpirePopup from "./Others/ExpirePopup.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signup from "./Signup.js";
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "./Others/TabSelector.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import "./ParticularProperty.css";
import { Button, Image } from "react-bootstrap";
import { useEffect } from "react";
import Map from "./Others/Map.js";

import CustomCarousel from "./Others/CustomCarousel.js";
import Downloads from "./Others/Downloadmodal.jsx";
const ParticularProperty = (props) => {
  const { user, types } = props;
  const [signinmodal, setSigninmodal] = useState(false);
  const [loginmodal, setLoginmodal] = useState(false);
  // const [customPopupmodal, setCustomPopupmodal] = useState(false);
  // const [customWhatsappPopupmodal, setCustomWhatsappPopupmodal] =
  //   useState(false);
  const [expirePopupmodal, setExpirePopupModal] = useState(false);
  // const [customePopupData, setCustomPopupData] = useState(1); //
  // const [customeWhatsappPopupData, setCustomWhatsappPopupData] = useState(1); //

  const loginModal = () => setLoginmodal(!loginmodal);
  // const customPopupModal = () => {
  //   if (customPopupmodal) {
  //     window.location.reload();
  //   }
  //   setCustomPopupmodal(!customPopupmodal);
  // };
  const ExpirePopupModal = () => {
    if (expirePopupmodal) {
      window.location.href = "/premium";
    }
    setExpirePopupModal(!expirePopupmodal);
  };
  const [downloadmodel, setdownloadmodel] = useState(false);

  const userAgent = navigator.userAgent;

  const osType = (() => {
    if (userAgent.includes("Windows")) {
      return "Windows";
    } else if (userAgent.includes("Mac")) {
      return "Mac OS";
    } else if (userAgent.includes("Android")) {
      return "Android";
    } else {
      return "Unknown";
    }
  })();
  const downloadlink = () => {
    // if (osType == "Android") {
    //   window.open(
    //     "https://play.google.com/store/apps/details?id=com.codingislife.citynect",
    //     "_blank",
    //     "rel=noopener noreferrer"
    //   );
    // } else if (osType == "Mac OS") {
    //   window.open(
    //     "https://apps.apple.com/",
    //     "_blank",
    //     "rel=noopener noreferrer"
    //   );
    // } else {
    //   setdownloadmodel(true);
    // }

    setdownloadmodel(true);
  };
  // const customwhatsappPopupModal = (phone) => {
  //   if (customWhatsappPopupmodal) {
  //     if (typeof phone === "string") {
  //       window.location.href = `https://wa.me/${phone}`;
  //     } else {
  //       console.error("Invalid phone number format");
  //     }
  //   }
  //   setCustomWhatsappPopupmodal(!customWhatsappPopupmodal);
  // };

  const signinModal = () => setSigninmodal(!signinmodal);
  const [property, setProperty] = useState();
  const [selectedTab, setSelectedTab] = useTabs([
    "details",
    "amenities",
    "pricing",
  ]);
  const [showReportBlock, setShowReportBlock] = useState(false);
  const toggleReportBlock = () => {
    setShowReportBlock(!showReportBlock);
  };
  const [showReportButtonForProperty, setShowReportButtonForProperty] =
    useState(null);
  const handleMouseEnter = (propertyId) => {
    setShowReportButtonForProperty(propertyId);
  };

  const handleMouseLeave = () => {
    setShowReportButtonForProperty(null);
  };
  const reportProperty = async (reason, id, ownerId) => {
    if (user) {
      try {
        const data = {
          userId: user.id,
          propertyId: id,
          ownerId: ownerId,
          reportTopic: reason,
        };
        const response = await fetch(
          `${process.env.REACT_APP_API_IP}/report/Report-Property`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        if (response.ok) {
          toast.success("Property Reported Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          // console.error("Failed to submit property:", response.status);
        }
      } catch (error) {
        // console.error("Error submitting property:", error);
      }
    } else {
      toast.error("Sinup to report Property", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const toggleBookmark = async (id) => {
    if (!user) {
      signinModal();
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_IP}/user/saved`,
          {
            method: "POST",
            body: JSON.stringify({ id: user.id, propertyId: id }), // Convert object to JSON string
            headers: {
              "Content-Type": "application/json", // Set the correct content type for JSON
            },
          }
        );
        if (response.ok) {
          window.location.reload();
        } else {
          // console.error("Failed to submit property:", response.status);
        }
      } catch (error) {
        // console.error("Error submitting property:", error);
      }
    }
  };
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback(
    (index) => {
      if (property && property.photoUrls) {
        // Ensure the provided index is within the valid range and has a valid URL
        let validIndex = index;

        if (!property.photoUrls[index]) {
          // Find the first valid image/video index if the provided index is not valid
          validIndex = property.photoUrls.findIndex((url) => url);
        }

        // Set the current image or video index
        setCurrentImage(validIndex);
        setIsViewerOpen(true);
      } else {
        // Handle the case where property or photoUrls is undefined
        console.error("Property or photoUrls is undefined");
      }
    },
    [property]
  );

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const [showInstructions, setShowInstructions] = useState(false);
  const [address, setAddress] = useState([]);
  const { id } = useParams();
  const hasValidValue = (value) => value && value.trim() !== "";

  // useEffect(() => {
  //   // Set up the payload for your POST request
  //   const data = {
  //     userId: user ? user.id : null,
  //   };

  //   console.log("User ID:", data.userId); // Log user ID for debugging

  //   // Set up the options for the fetch request
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   };

  //   // Make the fetch request
  //   fetch(`${process.env.REACT_APP_API_IP}/property/${id}`, requestOptions)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setProperty(data);

  //       // Set up the address based on the response
  //       const address = {
  //         coordinates: [
  //           parseFloat(data.address.latitude) || 23.0225,
  //           parseFloat(data.address.logitude) || 72.5714,
  //         ],
  //         label: data.title,
  //         link: `/particular-property/${data.id}`,
  //       };

  //       setAddress([address]);
  //     })
  //     .catch((err) => console.log(err));

  //   // eslint-disable-next-line
  // }, [user, id]);

  const fetchData = async () => {
    try {
      const data = {
        userId: user ? user.id : null, // Send null if user.id is null
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_IP}/property/vidghfy8u/vhidbfu/${id}/jnebfhue`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        const data = await response.json();
        setProperty(data.data.property);
        setProperties(data.data.recommendedProperties);
        // Set up the address based on the response
        const address = {
          coordinates: [
            parseFloat(data.data.property.address.latitude) || 23.0225,
            parseFloat(data.data.property.address.logitude) || 72.5714,
          ],
          label: data.title,
          link: `/particular-property/${data.id}`,
        };

        setAddress([address]);
      } else {
        console.log("Error fetching data");
      }
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [user, id]);

  const [properties, setProperties] = useState();
  // useEffect(() => {
  //   if (!property) {
  //     // Property is not available yet, you can choose to return early or handle it in a different way.
  //     return;
  //   }

  //   // Set up the payload for your POST request
  //   const data = {
  //     userId: user ? user.id : null,
  //   };

  //   // Set up the options for the fetch request
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   };

  //   // Make the fetch request
  //   fetch(
  //     `${process.env.REACT_APP_API_IP}/property/v2/Verified-properties`,
  //     requestOptions
  //   )
  //     .then((res) => res.json())
  //     .then((responseData) => {
  //       // Filter the data based on your criteria
  //       const filteredData = responseData.filter((item) => {
  //         return (
  //           item.subtype === property.subtype ||
  //           item.address.area === property.address.area ||
  //           (item.rent >= property.rent - 10000 &&
  //             item.rent <= property.rent + 10000)
  //         );
  //       });

  //       // Set the filtered data to the state
  //       setProperties(filteredData);
  //     })
  //     .catch((err) => console.log(err));

  //   // eslint-disable-next-line
  // }, [property, user]);

  // useEffect(() => {
  //   // Set up the payload for your POST request
  //   const data = {
  //     id: user ? user.id : null,
  //   };

  //   // Set up the options for the fetch request
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   };

  //   // Make the fetch request
  //   fetch(
  //     `${process.env.REACT_APP_API_IP}/property/v2/Verified-properties`,
  //     requestOptions
  //   )
  //     .then((res) => res.json())
  //     .then((responseData) => {
  //       // Filter the data based on your criteria, excluding the current property
  //       const filteredData = responseData.filter((item) => {
  //         console.log(property);
  //         return (
  //           item.id !== property.id && // Exclude the current property
  //           (item.subtype === property.subtype ||
  //             item.address.area === property.address.area ||
  //             (item.rent >= property.rent - 10000 &&
  //               item.rent <= property.rent + 10000))
  //         );
  //       });

  //       // Set the filtered data to the state
  //       setProperties(filteredData);
  //     })
  //     .catch((err) => console.log(err));
  // }, [property, user]);

  const contactOwner = async (id, type) => {
    if (!user) {
      signinModal();
    } else if (!user.isVerified) {
      signinModal();
    } else {
      if (user.credit > 0) {
        // setCustomPopupData(user.propertyCount); //
        try {
          const data = {
            userId: user.id,
            propId: id,
            propType: type,
            contactType: "call",
          };
          const response = await fetch(
            `${process.env.REACT_APP_API_IP}/user/dfjkbdhf/vjhdbfuh/contact`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
          );
          if (response.ok) {
            // if (!user.isPremium) {
            //   customPopupModal();
            // } else {
            //   window.location.href = `tel:${property.phone}`;
            //   // window.location.reload();
            // }
            window.location.reload();
          } else {
            // console.error("Failed to submit property:", response.status);
          }
        } catch (error) {
          // console.error("Error submitting property:", error);
        }
      } else {
        ExpirePopupModal();
        // customPopupModal();
      }
    }
  };

  const sendmessage = async (id, phone) => {
    if (!user) {
      signinModal();
    } else if (!user.isVerified) {
      signinModal();
    } else {
      if (user.contacted_property.includes(id)) {
        window.location.href = `https://wa.me/${phone}`;
      } else {
        if (user.propertyCount > 0) {
          // setCustomPopupData(user.propertyCount);
          try {
            const data = {
              id: user.id,
              propertyId: id,
            };
            const response = await fetch(
              `${process.env.REACT_APP_API_IP}/property/contacted`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
            );
            if (response.ok) {
              // if (!user.isPremium) {
              //   customwhatsappPopupModal(phone);
              // } else {
              //   window.location.href = `https://wa.me/${phone}`;
              // }
              window.location.href = `https://wa.me/${phone}`;
            } else {
              // console.error("Failed to submit property:", response.status);
            }
          } catch (error) {
            // console.error("Error submitting property:", error);
          }
        } else {
          ExpirePopupModal();
        }
      }
    }
  };

  const handleShareClick = async (datas) => {
    try {
      if (navigator.share) {
        // Determine the rent value based on the given property structure
        let rent;
        if (datas.type === "private flat") {
          rent = `₹ ${datas.totalFlatRent}`;
        } else if (datas.sharingType.singleRent) {
          rent = `₹ ${datas.sharingType.singleRent}`;
        } else if (datas.sharingType.doubleRent) {
          rent = `₹ ${datas.sharingType.doubleRent}`;
        } else if (datas.sharingType.tripleRent) {
          rent = `₹ ${datas.sharingType.tripleRent}`;
        } else {
          rent = `₹ ${datas.rentEachHead}`;
        }

        await navigator.share({
          text: `
                Hey, ${datas.name} is looking for a ${
            types == "sharing flat" ? "flatmate" : "tenant"
          } in a spacious ${datas.bedroom} ${datas.subtype}.🏡
                🛋️ Furnishing Type: ${datas.furnishedType}
                📍 Location: ${datas.address.area},${datas.address.city}
                ₹ Rent: ${rent} / Month
                -> Check out more details on the Citynect app:
                https://play.google.com/store/apps/details?id=com.codingislife.citynect
                citynect - Simplifying Bachelor Housing Search [Flat & Flatmates India]`,
        });
      } else {
        // Fallback for browsers that don't support the Web Share API
        console.log("Web Share API is not supported.");
      }
    } catch (error) {
      // console.error("Error sharing:", error);
    }
  };

  if (!property) {
    return (
      <div className="text-center fs-4">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ); // You can show a loading message or spinner
  }
  if (!properties) {
    return (
      <div className="text-center fs-4">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ); // You can show a loading message or spinner
  }

  return (
    <div className="bg-lightblue">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <div className="container py-4">
        <div id="" className="row">
          <h2 id="addressHeading" className=" col-lg-8 col-sm-12">
            {property.title} {property.address.pincode}{" "}
            {property.isPremium === 1 && (
              <FontAwesomeIcon
                className="me-2 text yellow"
                style={{ color: "gold" }}
                icon={faCrown}
              />
            )}
          </h2>
          <div className=" col-lg-2 flex justify-content-around" id="IconGroup">
            {/* <span
              className={`bookmark-button ShareIcons heart ${
                user && user.saved_property.includes(property.id)
                  ? "bookmarked"
                  : ""
              }`}
              onClick={() => {
                toggleBookmark(property.id);
              }}
            >
              {!(user && user.saved_property.includes(property.id)) ? (
                <FontAwesomeIcon icon={faHeart} />
              ) : (
                <FontAwesomeIcon icon={fasolidHeart} />
              )}
            </span> */}
            <span
              className={`bookmark-button ShareIcons heart ${
                property.isSaved ? "bookmarked" : ""
              }`}
              onClick={() => {
                toggleBookmark(property.id);
              }}
            >
              {property.isSaved ? (
                <FontAwesomeIcon icon={fasolidHeart} />
              ) : (
                <FontAwesomeIcon icon={faHeart} />
              )}
            </span>
            <span className="ShareIcons">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                style={{ zIndex: "1" }}
                onClick={() => handleShareClick(property)}
              />
            </span>
            <span
              className="ShareIcons"
              onMouseEnter={() => handleMouseEnter(property.id)}
              onMouseLeave={handleMouseLeave}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </span>
            {showReportButtonForProperty === property.id && (
              <div
                className="reportBlock"
                onMouseEnter={() => handleMouseEnter(property.id)}
                onMouseLeave={handleMouseLeave}
              >
                <h6 className="text-center">Report Property </h6>
                <ul>
                  <li
                    role="button"
                    onClick={() => {
                      reportProperty("Rent Out", property.id, property.ownerId);
                    }}
                  >
                    Rent Out
                  </li>
                  <li
                    role="button"
                    onClick={() => {
                      reportProperty(
                        "Broker's Post",
                        property.id,
                        property.ownerId
                      );
                    }}
                  >
                    Broker's Post
                  </li>
                  <li
                    role="button"
                    onClick={() => {
                      reportProperty(
                        "Asking for Brokerage",
                        property.id,
                        property.ownerId
                      );
                    }}
                  >
                    Asking for Brokerage
                  </li>
                  <li
                    role="button"
                    onClick={() => {
                      reportProperty(
                        "Not Answering",
                        property.id,
                        property.ownerId
                      );
                    }}
                  >
                    Not Answering
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div id="propertyPhotos" className="mobileAndTab-hide">
          <div className="col-md-9 col-8" style={{ maxHeight: "500px" }}>
            {property?.photoUrls[0]?.endsWith(".mp4") ? (
              <video
                width="100%"
                height="500px"
                controls
                onClick={() => openImageViewer(0)}
              >
                <source src={property.photoUrls[0]} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={
                  property.photoUrls[0] ||
                  "https://citynect1.s3.ap-south-1.amazonaws.com/Image+not+available.png"
                }
                onClick={() => openImageViewer(0)}
                alt="photos"
                width={"100%"}
                height={"500px"}
              />
            )}
          </div>
          <div
            className="col-md-3 col-4"
            id="SecondaryImageBox"
            style={{ maxHeight: "500px" }}
          >
            <div className="mobileAndTab-hide">
              {property?.photoUrls[1]?.endsWith(".mp4") ? (
                <video
                  width="100%"
                  height="100%"
                  controls
                  onClick={() => openImageViewer(1)}
                >
                  <source src={property.photoUrls[1]} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={
                    property.photoUrls[1] ||
                    "https://citynect1.s3.ap-south-1.amazonaws.com/Image+not+available.png"
                  }
                  onClick={() => openImageViewer(1)}
                  alt="photos"
                  width={"100%"}
                  height={"100%"}
                />
              )}
            </div>
            <span className="my-2"></span>
            <div className="">
              <div style={{ position: "relative", height: "100%" }}>
                {property?.photoUrls[2]?.endsWith(".mp4") ? (
                  <video
                    width="100%"
                    height="100%"
                    controls
                    onClick={() => openImageViewer(2)}
                  >
                    <source src={property.photoUrls[2]} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={
                      property.photoUrls[2] ||
                      "https://citynect1.s3.ap-south-1.amazonaws.com/Image+not+available.png"
                    }
                    alt="property"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    onClick={() => openImageViewer(2)}
                  />
                )}
                {property.photoUrls.length > 3 && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity as needed
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white", // Text color
                      fontSize: "24px", // Text font size
                    }}
                    onClick={() => openImageViewer(3)}
                  >
                    +{property.photoUrls.length - 3} More
                  </div>
                )}
              </div>
            </div>
          </div>

          {isViewerOpen && (
            <ImageViewer
              src={property.photoUrls}
              currentIndex={currentImage}
              onClose={closeImageViewer}
              disableScroll={true}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
                height: "90%",
                top: "10%",
              }}
              closeOnClickOutside={true}
            />
          )}
        </div>

        <div className="LaptopHide">
          <CustomCarousel photoUrls={property.photoUrls} />
          <div id="iconBox" className="LaptopHide">
            {/* <span
              className={`bookmark-button ShareIcons heart ${
                user &&
                user.saved_property &&
                user.saved_property.includes(property.id)
                  ? "bookmarked"
                  : ""
              }`}
              onClick={() => {
                toggleBookmark(property.id);
              }}
            >
              {user &&
              user.saved_property &&
              user.saved_property.includes(property.id) ? (
                <FontAwesomeIcon icon={fasolidHeart} />
              ) : (
                <FontAwesomeIcon icon={faHeart} />
              )}
            </span> */}

            <span
              className={`bookmark-button ShareIcons heart ${
                property.isSaved ? "bookmarked" : ""
              }`}
              onClick={() => {
                toggleBookmark(property.id);
              }}
            >
              {property.isSaved ? (
                <FontAwesomeIcon icon={fasolidHeart} />
              ) : (
                <FontAwesomeIcon icon={faHeart} />
              )}
            </span>

            <span
              className="ShareIcons"
              style={{ zIndex: "1" }}
              // onClick={() => {
              //   handleShareClick(
              //     property.title,
              //     `/particular-property/${property.id}`
              //   );
              // }}
              onClick={() => handleShareClick(property)}
            >
              <FontAwesomeIcon icon={faShareFromSquare} />
            </span>
            <span
              className="ShareIcons"
              onClick={toggleReportBlock}
              style={{ zIndex: "1" }}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </span>
            {showReportBlock && (
              <div className="reportBlock">
                <h6 className="text-center d-flex justify-content-around">
                  Report Property{" "}
                  <FontAwesomeIcon onClick={toggleReportBlock} icon={faClose} />
                </h6>
                <ul>
                  <li
                    role="button"
                    onClick={() => {
                      reportProperty("Rent Out", property.id, property.ownerId);
                    }}
                  >
                    Rent Out
                  </li>
                  <li
                    role="button"
                    onClick={() => {
                      reportProperty(
                        "Broker's Post",
                        property.id,
                        property.ownerId
                      );
                    }}
                  >
                    Broker's Post
                  </li>
                  <li
                    role="button"
                    onClick={() => {
                      reportProperty(
                        "Asking for Brokerage",
                        property.id,
                        property.ownerId
                      );
                    }}
                  >
                    Asking for Brokerage
                  </li>
                  <li
                    role="button"
                    onClick={() => {
                      reportProperty(
                        "Not Answering",
                        property.id,
                        property.ownerId
                      );
                    }}
                  >
                    Not Answering
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div id="propertyDetails" className="row">
          <div className="col-md-8">
            <div className="detailsBox mobileAndTab-hide">
              <div className="boxHeader"> Property Details :</div>
              <div className="row innerDetailsBox">
                <div className="col-md">
                  <div className="">
                    <div className="labelTextForDetail">Property type :</div>
                    <div className="valueTextForDetail">{property.subtype}</div>
                  </div>
                  <div className="">
                    <div className="labelTextForDetail">Property Size :</div>
                    <div className="valueTextForDetail">
                      {property.bedroom === "1rk"
                        ? "1 RK"
                        : property.bedroom === "1BHK"
                        ? "1 BHK"
                        : property.bedroom === "2BHK"
                        ? "2 BHK"
                        : property.bedroom === "3BHK"
                        ? "3 BHK"
                        : property.bedroom === "4BHK"
                        ? "4 BHK"
                        : property.bedroom === "5BHK"
                        ? "5 BHK"
                        : property.bedroom}
                    </div>
                  </div>
                  <div className="">
                    <div className="labelTextForDetail">Furnishing :</div>
                    <div className="valueTextForDetail">
                      {property.furnishedType === "fully-furnished"
                        ? "Fully-Furnished"
                        : property.furnishedType === "unfurnished"
                        ? "Unfurnished"
                        : property.furnishedType === "semi-furnished"
                        ? "Semi-Furnished"
                        : property.furnishedType}
                    </div>
                  </div>
                  {property.type === "pg" && (
                    <div className="">
                      <div className="labelTextForDetail">Notice Period :</div>
                      <div className="valueTextForDetail">
                        {property.noticePeriond}
                        {" month"}
                      </div>
                    </div>
                  )}
                  {property.type === "sharing flat" && (
                    <div className="">
                      <div className="labelTextForDetail">
                        Vacancy Available :
                      </div>
                      <div className="valueTextForDetail">
                        {[
                          property.sharingType.singleSharing && "Single",
                          property.sharingType.doubleSharing && "Double",
                          property.sharingType.tripleSharing && "Triple",
                        ]
                          .filter(Boolean) // Removes any falsey values (like null or undefined)
                          .join("/")}
                        {" Occupancy"}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md">
                  <div className="">
                    <div className="labelTextForDetail">Available From :</div>
                    <div className="valueTextForDetail">
                      {property.availableFrom}
                      {/* {new Date(property.availableFrom).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )} */}
                    </div>
                  </div>
                  <div className="">
                    <div className="labelTextForDetail">Available For :</div>
                    <div className="valueTextForDetail">
                      {property.memberedAllowed === "male"
                        ? "Male"
                        : property.memberedAllowed === "female"
                        ? "Female"
                        : property.memberedAllowed === "both"
                        ? "Both"
                        : property.memberedAllowed}
                    </div>
                  </div>
                  {property.type === "pg" && (
                    <div className="">
                      <div className="labelTextForDetail">
                        {" "}
                        Vacancy Available :
                      </div>
                      <div className="valueTextForDetail">
                        {[
                          property.sharingType.singleSharing && "Single",
                          property.sharingType.doubleSharing && "Double",
                          property.sharingType.tripleSharing && "Triple",
                        ]
                          .filter(Boolean) // Removes any falsey values (like null or undefined)
                          .join("/")}
                        {" Occupancy"}
                      </div>
                    </div>
                  )}

                  {property.type === "sharing flat" && (
                    <div className="">
                      <div className="labelTextForDetail">
                        Washroom Attached
                      </div>
                      <div className="valueTextForDetail">
                        {property.washroomAttached === true ? "Yes" : "No"}
                      </div>
                    </div>
                  )}
                  <div className="">
                    <div className="labelTextForDetail">Non-Veg allowed :</div>
                    <div className="valueTextForDetail">
                      {property.nonVegAllowed === false ? "No" : "Yes"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailsBox mobileAndTab-hide">
              <div className="boxHeader"> Description :</div>
              <div className="valueTextForDetail innerDetailsBox d-flex flex-wrap ">
                {property.description}
              </div>
            </div>
            <div className="detailsBox mobileAndTab-hide">
              <div className="boxHeader"> Amenities :</div>
              <div className="innerDetailsBox d-flex flex-wrap">
                {property.amenities.includes("electricity") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Electricity.png"
                      alt="Amenities"
                    />
                    <span>Electricity</span>
                  </div>
                )}

                {property.amenities.includes("fan") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/fan.png"
                      alt="Amenities"
                    />
                    <span>Fan</span>
                  </div>
                )}
                {property.amenities.includes("lift") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/lift.png"
                      alt="Amenities"
                    />
                    <span>Lift</span>
                  </div>
                )}
                {property.amenities.includes("wifi") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/wifi.png"
                      alt="Amenities"
                    />
                    <span>Wifi</span>
                  </div>
                )}
                {property.amenities.includes("desk") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/desk.png"
                      alt="Amenities"
                    />
                    <span>Desk</span>
                  </div>
                )}

                {property.amenities.includes("guard") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/guard.png"
                      alt="Amenities"
                    />
                    <span>Guard</span>
                  </div>
                )}
                {property.amenities.includes("sofa") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/sofa.png"
                      alt="Amenities"
                    />
                    <span>Sofa</span>
                  </div>
                )}

                {property.amenities.includes("machine") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/machine.png"
                      alt="Amenities"
                    />
                    <span>Machine</span>
                  </div>
                )}

                {property.amenities.includes("water") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Water.png"
                      alt="Amenities"
                    />
                    <span>Water</span>
                  </div>
                )}
                {property.amenities.includes("kitchen") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Kitchen.png"
                      alt="Amenities"
                    />
                    <span>Kitchen</span>
                  </div>
                )}
                {property.amenities.includes("AC") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/AC.png"
                      alt="Amenities"
                    />
                    <span>AC</span>
                  </div>
                )}
                {property.amenities.includes("balcony") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/balcony.png"
                      alt="Amenities"
                    />
                    <span>Balcony</span>
                  </div>
                )}

                {property.amenities.includes("CCTV") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/CCTV.png"
                      alt="Amenities"
                    />
                    <span>CCTV</span>
                  </div>
                )}
                {property.amenities.includes("stove") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/stove.png"
                      alt="Amenities"
                    />
                    <span>Stove</span>
                  </div>
                )}

                {property.amenities.includes("parking") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Parking.png"
                      alt="Amenities"
                    />
                    <span>Parking</span>
                  </div>
                )}

                {property.amenities.includes("2-wheeler-parking") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/2-wheel-parking.png"
                      alt="Amenities"
                    />
                    <span>2-wheeler-Parking</span>
                  </div>
                )}

                {property.amenities.includes("tv") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/T.V.png"
                      alt="Amenities"
                    />
                    <span>T.V.</span>
                  </div>
                )}
                {property.amenities.includes("2-wheeler-parking") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Parking.png"
                      alt="Amenities"
                    />
                    <span>Parking</span>
                  </div>
                )}
                {property.amenities.includes("free-wifi") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Free wifi.png"
                      alt="Amenities"
                    />
                    <span>Free Wifi</span>
                  </div>
                )}
                {property.amenities.includes("cooking") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Cooking.png"
                      alt="Amenities"
                    />
                    <span>Cooking</span>
                  </div>
                )}
                {property.amenities.includes("laundry") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Laundry.png"
                      alt="Amenities"
                    />
                    <span>Laundry</span>
                  </div>
                )}
                {property.amenities.includes("fridge") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Fridge.png"
                      alt="Amenities"
                    />
                    <span>Fridge</span>
                  </div>
                )}
                {property.amenities.includes("ro-water") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/RO Water.png"
                      alt="Amenities"
                    />
                    <span>R.O. Water</span>
                  </div>
                )}
                {property.amenities.includes("24*7-water") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Water.png"
                      alt="Amenities"
                    />
                    <span>24*7 Water</span>
                  </div>
                )}
                {property.amenities.includes("air-conditioner") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/AC.png"
                      alt="Amenities"
                    />
                    <span>A.C.</span>
                  </div>
                )}
                {property.amenities.includes("breakfast") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Breakfast.png"
                      alt="Amenities"
                    />
                    <span>Breakfast</span>
                  </div>
                )}
                {property.amenities.includes("gyser") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Gyser.png"
                      alt="Amenities"
                    />
                    <span>Gyser</span>
                  </div>
                )}
                {property.amenities.includes("lunch") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Lunch.png"
                      alt="Amenities"
                    />
                    <span>Lunch</span>
                  </div>
                )}
                {property.amenities.includes("security") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Security.png"
                      alt="Amenities"
                    />
                    <span>Security</span>
                  </div>
                )}
                {property.amenities.includes("microwave") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Microwave.png"
                      alt="Amenities"
                    />
                    <span>Microwave</span>
                  </div>
                )}
                {property.amenities.includes("fans") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Fen.png"
                      alt="Amenities"
                    />
                    <span>Fans</span>
                  </div>
                )}
                {property.amenities.includes("wardrobe") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Wardrobe.png"
                      alt="Amenities"
                    />
                    <span>Wardrobe</span>
                  </div>
                )}
                {property.amenities.includes("cctv") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/CCTV.png"
                      alt="Amenities"
                    />
                    <span>C.C.T.V.</span>
                  </div>
                )}
                {property.amenities.includes("house-keeping") && (
                  <div className="AmenityBox col-4 d-lg-none">
                    <img
                      className="amenities-size"
                      src="/emenities/Housekeeping.png"
                      alt="Amenities"
                    />
                    <span>House Keeping</span>
                  </div>
                )}
              </div>
            </div>

            {property.type === "sharing flat" && (
              <div className="detailsBox mobileAndTab-hide">
                <div className="boxHeader"> Preferences :</div>
                <div className="innerDetailsBox d-flex flex-wrap">
                  {property.preferenceTypes.includes("Health Conscious") && (
                    <div className="AmenityBox col-3 col-lg-2">
                      <img
                        className="amenities-size"
                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-wellness-4049800.svg"
                        alt="Amenities"
                      />
                      <span>Health Conscious</span>
                    </div>
                  )}
                  {property.preferenceTypes.includes("Social Butterfly") && (
                    <div className="AmenityBox col-3 col-lg-2">
                      <img
                        className="amenities-size"
                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-networking-138311.svg"
                        alt="Amenities"
                      />
                      <span>Social Butterfly</span>
                    </div>
                  )}
                  {property.preferenceTypes.includes("Pet Lover") && (
                    <div className="AmenityBox col-3 col-lg-2">
                      <img
                        className="amenities-size"
                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-pet-lover-3347039.svg"
                        alt="Amenities"
                      />
                      <span>Pet Lover</span>
                    </div>
                  )}

                  {property.preferenceTypes.includes("Vegetarian") && (
                    <div className="AmenityBox col-3 col-lg-2">
                      <img
                        className="amenities-size"
                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-leaves-1374829.svg"
                        alt="Amenities"
                      />
                      <span>Vegetarian</span>
                    </div>
                  )}
                  {property.preferenceTypes.includes("Night Owl") && (
                    <div className="AmenityBox col-3 col-lg-2">
                      <img
                        className="amenities-size"
                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-night-time-5876451.svg"
                        alt="Amenities"
                      />
                      <span>Night Owl</span>
                    </div>
                  )}
                  {property.preferenceTypes.includes("Foodie") && (
                    <div className="AmenityBox col-3 col-lg-2">
                      <img
                        className="amenities-size"
                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-pizza-2340181.svg"
                        alt="Amenities"
                      />
                      <span>Foodie</span>
                    </div>
                  )}
                  {property.preferenceTypes.includes("Early Riser") && (
                    <div className="AmenityBox col-3 col-lg-2">
                      <img
                        className="amenities-size"
                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-sun-6612922.svg"
                        alt="Amenities"
                      />
                      <span>Early Riser</span>
                    </div>
                  )}
                  {property.preferenceTypes.includes("Alcohol Enthusiast") && (
                    <div className="AmenityBox col-3 col-lg-2">
                      <img
                        className="amenities-size"
                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-beer-6403759.svg"
                        alt="Amenities"
                      />
                      <span> Alcohol Enthusiast</span>
                    </div>
                  )}
                  {property.preferenceTypes.includes("Smoking") && (
                    <div className="AmenityBox col-3 col-lg-2">
                      <img
                        className="amenities-size"
                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-smoking-1473219.svg"
                        alt="Amenities"
                      />
                      <span> Smoking </span>
                    </div>
                  )}

                  {property.preferenceTypes.includes("Adventure Seeker") && (
                    <div className="AmenityBox col-3 col-lg-2">
                      <img
                        className="amenities-size"
                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-trip-1650697.svg"
                        alt="Amenities"
                      />
                      <span> Adventure Seeker </span>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="flex border-b border-gray-300 LaptopHide">
              <TabSelector
                isActive={selectedTab === "details"}
                onClick={() => setSelectedTab("details")}
              >
                Details
              </TabSelector>
              <TabSelector
                isActive={selectedTab === "amenities"}
                onClick={() => setSelectedTab("amenities")}
              >
                Amenities
              </TabSelector>
              <TabSelector
                isActive={selectedTab === "pricing"}
                onClick={() => setSelectedTab("pricing")}
              >
                Pricing
              </TabSelector>
            </div>
            <div className="LaptopHide">
              <TabPanel hidden={selectedTab !== "details"}>
                <div className="detailsBox">
                  <div className="row innerDetailsBox">
                    <div className="col-md">
                      <div className="row">
                        <div className="labelTextForDetail col-5">
                          Property size
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail col-6">
                          {property.bedroom}
                        </div>
                      </div>
                      <div className="row">
                        <div className="labelTextForDetail col-5">
                          Property Type
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail col-6">
                          {property.subtype}
                        </div>
                      </div>
                      <div className="row">
                        <div className="labelTextForDetail col-5">
                          Furnishing{" "}
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail col-6">
                          {property.furnishedType === "fully-furnished"
                            ? "Fully-Furnished"
                            : property.furnishedType === "unfurnished"
                            ? "Unfurnished"
                            : property.furnishedType === "semi-furnished"
                            ? "Semi-Furnished"
                            : property.furnishedType}
                        </div>
                      </div>
                      {property.type === "pg" && (
                        <div className="row">
                          <div className="labelTextForDetail col-5">
                            Vacancy Available
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail col-6">
                            {[
                              property.sharingType.singleSharing && "Single",
                              property.sharingType.doubleSharing && "Double",
                              property.sharingType.tripleSharing && "Triple",
                            ]
                              .filter(Boolean) // Removes any falsey values (like null or undefined)
                              .join("/")}
                            {" Occupancy"}
                          </div>
                        </div>
                      )}
                      {property.type === "sharing flat" && (
                        <div className="row">
                          <div className="labelTextForDetail col-5">
                            Washroom Attached
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail col-6">
                            {property.washroomAttached === true ? "Yes" : "No"}
                          </div>
                        </div>
                      )}

                      {/* {property.type === "pg" && (
                        <div className="SingleDetailBox row">
                          <div className="labelTextForDetail col-5">
                            Available In
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail col-6">
                            {property.sharing === "1" && "Single Sharing"}
                            {property.sharing === "2" && "Double Sharing"}
                            {property.sharing === "3" && "3 Sharing"}
                            {property.sharing === "4+" && "4+ Sharing"}
                          </div>
                        </div>
                      )} */}
                    </div>
                    <div className="col-md">
                      <div className="row">
                        <div className="labelTextForDetail col-5">
                          Available From
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail col-6">
                          {property.availableFrom}
                          {/* {new Date(property.availableFrom).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )} */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="labelTextForDetail col-5">
                          Available For
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail col-6">
                          {property.memberedAllowed === "male"
                            ? "Male"
                            : property.memberedAllowed === "female"
                            ? "Female"
                            : property.memberedAllowed === "both"
                            ? "Both"
                            : property.memberedAllowed}
                        </div>
                      </div>
                      {property.type === "sharing flat" && (
                        <div className="row">
                          <div className="labelTextForDetail col-5">
                            Vacancy Available
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail col-6">
                            {[
                              property.sharingType.singleSharing && "Single",
                              property.sharingType.doubleSharing && "Double",
                              property.sharingType.tripleSharing && "Triple",
                            ]
                              .filter(Boolean) // Removes any falsey values (like null or undefined)
                              .join("/")}
                            {" Occupancy"}
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="labelTextForDetail col-5">
                          Non-Veg allowed
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail col-6">
                          {property.nonVegAllowed ? "Yes" : "No"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel hidden={selectedTab !== "amenities"}>
                <div className="detailsBox">
                  <div className="innerDetailsBox d-flex  flex-wrap">
                    <div className="amenitiesBox">
                      {property.amenities.includes("electricity") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Electricity.png"
                            alt="Amenities"
                          />
                          <span>Electricity</span>
                        </div>
                      )}

                      {property.amenities.includes("fan") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/fan.png"
                            alt="Amenities"
                          />
                          <span>Fan</span>
                        </div>
                      )}
                      {property.amenities.includes("lift") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/lift.png"
                            alt="Amenities"
                          />
                          <span>Lift</span>
                        </div>
                      )}
                      {property.amenities.includes("wifi") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/wifi.png"
                            alt="Amenities"
                          />
                          <span>Wifi</span>
                        </div>
                      )}
                      {property.amenities.includes("desk") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/desk.png"
                            alt="Amenities"
                          />
                          <span>Desk</span>
                        </div>
                      )}

                      {property.amenities.includes("guard") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/guard.png"
                            alt="Amenities"
                          />
                          <span>Guard</span>
                        </div>
                      )}
                      {property.amenities.includes("sofa") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/sofa.png"
                            alt="Amenities"
                          />
                          <span>Sofa</span>
                        </div>
                      )}

                      {property.amenities.includes("machine") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/machine.png"
                            alt="Amenities"
                          />
                          <span>Machine</span>
                        </div>
                      )}

                      {property.amenities.includes("water") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Water.png"
                            alt="Amenities"
                          />
                          <span>Water</span>
                        </div>
                      )}
                      {property.amenities.includes("kitchen") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Kitchen.png"
                            alt="Amenities"
                          />
                          <span>Kitchen</span>
                        </div>
                      )}
                      {property.amenities.includes("AC") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/AC.png"
                            alt="Amenities"
                          />
                          <span>AC</span>
                        </div>
                      )}
                      {property.amenities.includes("balcony") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/balcony.png"
                            alt="Amenities"
                          />
                          <span>Balcony</span>
                        </div>
                      )}

                      {property.amenities.includes("CCTV") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/CCTV.png"
                            alt="Amenities"
                          />
                          <span>CCTV</span>
                        </div>
                      )}
                      {property.amenities.includes("stove") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/stove.png"
                            alt="Amenities"
                          />
                          <span>Stove</span>
                        </div>
                      )}

                      {property.amenities.includes("parking") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Parking.png"
                            alt="Amenities"
                          />
                          <span>Parking</span>
                        </div>
                      )}

                      {property.amenities.includes("2-wheeler-parking") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/2-wheel-parking.png"
                            alt="Amenities"
                          />
                          <span>2-wheeler-Parking</span>
                        </div>
                      )}

                      {property.amenities.includes("tv") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/T.V.png"
                            alt="Amenities"
                          />
                          <span>T.V.</span>
                        </div>
                      )}
                      {property.amenities.includes("2-wheeler-parking") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Parking.png"
                            alt="Amenities"
                          />
                          <span>Parking</span>
                        </div>
                      )}
                      {property.amenities.includes("free-wifi") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Free wifi.png"
                            alt="Amenities"
                          />
                          <span>Free Wifi</span>
                        </div>
                      )}
                      {property.amenities.includes("cooking") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Cooking.png"
                            alt="Amenities"
                          />
                          <span>Cooking</span>
                        </div>
                      )}
                      {property.amenities.includes("laundry") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Laundry.png"
                            alt="Amenities"
                          />
                          <span>Laundry</span>
                        </div>
                      )}
                      {property.amenities.includes("fridge") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Fridge.png"
                            alt="Amenities"
                          />
                          <span>Fridge</span>
                        </div>
                      )}
                      {property.amenities.includes("ro-water") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/RO Water.png"
                            alt="Amenities"
                          />
                          <span>R.O. Water</span>
                        </div>
                      )}
                      {property.amenities.includes("24*7-water") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Water.png"
                            alt="Amenities"
                          />
                          <span>24*7 Water</span>
                        </div>
                      )}
                      {property.amenities.includes("air-conditioner") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/AC.png"
                            alt="Amenities"
                          />
                          <span>A.C.</span>
                        </div>
                      )}
                      {property.amenities.includes("breakfast") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Breakfast.png"
                            alt="Amenities"
                          />
                          <span>Breakfast</span>
                        </div>
                      )}
                      {property.amenities.includes("gyser") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Gyser.png"
                            alt="Amenities"
                          />
                          <span>Gyser</span>
                        </div>
                      )}
                      {property.amenities.includes("lunch") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Lunch.png"
                            alt="Amenities"
                          />
                          <span>Lunch</span>
                        </div>
                      )}
                      {property.amenities.includes("security") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Security.png"
                            alt="Amenities"
                          />
                          <span>Security</span>
                        </div>
                      )}
                      {property.amenities.includes("microwave") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Microwave.png"
                            alt="Amenities"
                          />
                          <span>Microwave</span>
                        </div>
                      )}
                      {property.amenities.includes("fans") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Fen.png"
                            alt="Amenities"
                          />
                          <span>Fans</span>
                        </div>
                      )}
                      {property.amenities.includes("wardrobe") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/Wardrobe.png"
                            alt="Amenities"
                          />
                          <span>Wardrobe</span>
                        </div>
                      )}
                      {property.amenities.includes("cctv") && (
                        <div className="AmenityBox col-3 col-lg-2">
                          <img
                            className="amenities-size"
                            src="/emenities/CCTV.png"
                            alt="Amenities"
                          />
                          <span>C.C.T.V.</span>
                        </div>
                      )}
                      {property.amenities.includes("house-keeping") && (
                        <div className="AmenityBox col-4 d-lg-none">
                          <img
                            className="amenities-size"
                            src="/emenities/Housekeeping.png"
                            alt="Amenities"
                          />
                          <span>House Keeping</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel hidden={selectedTab !== "pricing"}>
                <div className="detailsBox ">
                  <div className="innerDetailsBox">
                    <div className="bg-white rounded-2 my-1">
                      {property.type === "sharing flat" && (
                        <div className="row align-items-center">
                          {Object.entries(property.sharingType)
                            .filter(
                              ([key, value]) =>
                                value &&
                                (key === "singleRent" ||
                                  key === "doubleRent" ||
                                  key === "tripleRent")
                            ) // Filter out empty values and select only specific keys
                            .map(([key, value]) => (
                              <React.Fragment key={key}>
                                <div className="col-7 pricingLabel">
                                  {key === "singleRent"
                                    ? "Single Occupancy"
                                    : key === "doubleRent"
                                    ? "Double Occupancy"
                                    : key === "tripleRent"
                                    ? "Triple Occupancy"
                                    : ""}
                                </div>
                                <div className="col-1">:</div>
                                <div className="valueTextForDetail fw-bold col-4">
                                  {value}
                                </div>
                              </React.Fragment>
                            ))}
                        </div>
                      )}
                      {property.type === "pg" && (
                        <div className="row align-items-center">
                          {Object.entries(property.sharingType)
                            .filter(
                              ([key, value]) =>
                                value &&
                                (key === "singleRent" ||
                                  key === "doubleRent" ||
                                  key === "tripleRent")
                            ) // Filter out empty values and select only specific keys
                            .map(([key, value]) => (
                              <React.Fragment key={key}>
                                <div className="col-7 pricingLabel">
                                  {key === "singleRent"
                                    ? "Single Occupancy"
                                    : key === "doubleRent"
                                    ? "Double Occupancy"
                                    : key === "tripleRent"
                                    ? "Triple Occupancy"
                                    : ""}
                                </div>
                                <div className="col-1">:</div>
                                <div className="valueTextForDetail fw-bold col-4">
                                  {value}
                                </div>
                              </React.Fragment>
                            ))}
                        </div>
                      )}
                    </div>

                    {property.type == "sharing flat" ||
                      (property.type == "private flat" && (
                        <div className="bg-white rounded-2 my-1">
                          <div className="row align-items-center">
                            <div className="col-7 pricingLabel ">
                              {" "}
                              Rent (Per Month)
                            </div>
                            <div className="col-1">:</div>
                            <div className="valueTextForDetail fw-bold col-4">
                              {" "}
                              {property.rent || property.totalFlatRent}
                            </div>
                          </div>
                        </div>
                      ))}

                    <div className="bg-white rounded-2 my-1">
                      <div className="row align-items-center">
                        <div className="col-7 pricingLabel ">
                          {" "}
                          Notice Period (Per Month)
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail fw-bold col-4">
                          {" "}
                          {property.noticePeriond}
                        </div>
                      </div>
                    </div>

                    {property.type == "sharing flat" && (
                      // {property.rentEachHead && (
                      <div className="bg-white rounded-2 my-1">
                        <div className="row align-items-center">
                          <div className="col-7 pricingLabel">
                            {" "}
                            Total Flat Rent (Per Month)
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail fw-bold col-4">
                            {" "}
                            {property.totalFlatRent}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="bg-white rounded-2 my-1">
                      <div className="row align-items-center">
                        <div className="col-7 pricingLabel">
                          {" "}
                          Rent Negotiable
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail fw-bold col-4">
                          {property.isNegotiable ? "Yes" : "No"}
                        </div>
                      </div>
                    </div>

                    <div className="bg-white rounded-2 my-1">
                      <div className="row align-items-center">
                        <div className="col-7 pricingLabel">
                          {" "}
                          Deposit (in months)
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail fw-bold col-4">
                          {" "}
                          {property.deposit} rent
                        </div>
                      </div>
                    </div>

                    {/* <div className="bg-white rounded-2 my-2">
                      <div className="row align-items-center">
                        <div className="col-7 pricingLabel">
                          {" "}
                          Additional Cost
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail fw-bold col-4">
                          {" "}
                          {property.additionalCosts.cost1 ? "Yes" : "No"}
                        </div>
                      </div>
                      {property.additionalCosts.description1 &&
                        property.additionalCosts.cost1 && (
                          <div className="row align-items-center">
                            <div className="col-7 pricingLabel">
                              {" "}
                              {property.additionalCosts.description1}
                            </div>
                            <div className="col-1">:</div>
                            <div className="valueTextForDetail fw-bold col-4">
                              {" "}
                              {property.additionalCosts.cost1}
                            </div>
                          </div>
                        )}
                      {property.additionalCosts.description2 &&
                        property.additionalCosts.cost2 && (
                          <div className="row align-items-center">
                            <div className="col-7 pricingLabel">
                              {" "}
                              {property.additionalCosts.description2}
                            </div>
                            <div className="col-1">:</div>
                            <div className="valueTextForDetail fw-bold col-4">
                              {" "}
                              {property.additionalCosts.cost2}
                            </div>
                          </div>
                        )}
                      {property.additionalCosts.description3 &&
                        property.additionalCosts.cost3 && (
                          <div className="row align-items-center">
                            <div className="col-7 pricingLabel">
                              {" "}
                              {property.additionalCosts.description3}
                            </div>
                            <div className="col-1">:</div>
                            <div className="valueTextForDetail fw-bold col-4">
                              {" "}
                              {property.additionalCosts.cost3}
                            </div>
                          </div>
                        )}
                    </div> */}
                  </div>
                </div>
              </TabPanel>
            </div>

            {property.instructions && (
              <>
                {" "}
                <div className="detailsBox mobileAndTab-hide-1 ">
                  <div className="boxHeader"> Description :</div>
                  <div className="valueTextForDetail innerDetailsBox d-flex flex-wrap ">
                    {property.description}
                  </div>
                </div>
                <div className="detailsBox LaptopHide">
                  <span
                    className="text-primary"
                    role="button"
                    onClick={() => {
                      setShowInstructions(!showInstructions);
                    }}
                  >
                    {showInstructions ? (
                      <div className="grey p-3">
                        {" "}
                        Instructions / rules or regulations{" "}
                        <FontAwesomeIcon icon={faAngleDown} />
                      </div>
                    ) : (
                      <div className="grey p-3">
                        {" "}
                        Instructions / rules or regulations{" "}
                        <FontAwesomeIcon icon={faAngleDown} />
                      </div>
                    )}
                  </span>
                  {showInstructions && (
                    <div className="innerDetailsBox grey">
                      {property.instructions}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="col-md-4">
            <div className="detailsBox">
              <div className="boxHeader"> Address:</div>
              <div className="innerDetailsBox">
                <div className="" style={{ textTransform: "capitalize" }}>
                  {" "}
                  <FontAwesomeIcon className="grey" icon={faLocationDot} />{" "}
                  {property.address.streetAddress}, {property.address.area},{" "}
                  {property.address.city}, {property.address.pincode}{" "}
                </div>
                <div className="mapBox mt-3">
                  <Map addresses={address} height={"300px"} />
                </div>
              </div>
            </div>
            <div className="detailsBox mobileAndTab-hide">
              <div className="boxHeader"> Pricing :</div>
              <div className="detailsBox ">
                <div className="innerDetailsBox">
                  <div className="bg-white rounded-2 my-1">
                    {property.type == "pg" && (
                      <div className="row align-items-center">
                        {Object.entries(property.sharingType)
                          .filter(
                            ([key, value]) =>
                              value &&
                              (key === "singleRent" ||
                                key === "doubleRent" ||
                                key === "tripleRent")
                          ) // Filter out empty values and select only specific keys
                          .map(([key, value]) => (
                            <React.Fragment key={key}>
                              <div className="col-7 pricingLabel">
                                {key === "singleRent"
                                  ? "Single Occupancy"
                                  : key === "doubleRent"
                                  ? "Double Occupancy"
                                  : key === "tripleRent"
                                  ? "Triple Occupancy"
                                  : ""}
                              </div>
                              <div className="col-1">:</div>
                              <div className="valueTextForDetail fw-bold col-4">
                                {value}
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    )}
                    {property.type == "sharing flat" && (
                      <div className="row align-items-center">
                        {Object.entries(property.sharingType)
                          .filter(
                            ([key, value]) =>
                              value &&
                              (key === "singleRent" ||
                                key === "doubleRent" ||
                                key === "tripleRent")
                          ) // Filter out empty values and select only specific keys
                          .map(([key, value]) => (
                            <React.Fragment key={key}>
                              <div className="col-7 pricingLabel">
                                {key === "singleRent"
                                  ? "Single Occupancy"
                                  : key === "doubleRent"
                                  ? "Double Occupancy"
                                  : key === "tripleRent"
                                  ? "Triple Occupancy"
                                  : ""}
                              </div>
                              <div className="col-1">:</div>
                              <div className="valueTextForDetail fw-bold col-4">
                                {value}
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    )}
                  </div>

                  {property.type == "sharing flat" ||
                    (property.type == "private flat" && (
                      <div className="bg-white rounded-2 my-1">
                        <div className="row align-items-center">
                          <div className="col-7 pricingLabel ">
                            {" "}
                            Rent (Per Month)
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail fw-bold col-4">
                            {" "}
                            {property.rent || property.totalFlatRent}
                          </div>
                        </div>
                      </div>
                    ))}

                  <div className="bg-white rounded-2 my-1">
                    <div className="row align-items-center">
                      <div className="col-7 pricingLabel ">
                        {" "}
                        Notice Period (Per Month)
                      </div>
                      <div className="col-1">:</div>
                      <div className="valueTextForDetail fw-bold col-4">
                        {" "}
                        {property.noticePeriond}
                      </div>
                    </div>
                  </div>

                  {property.type == "sharing flat" && (
                    <div className="bg-white rounded-2 my-1">
                      <div className="row align-items-center">
                        <div className="col-7 pricingLabel">
                          {" "}
                          Total Flat Rent (Per Month)
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail fw-bold col-4">
                          {" "}
                          {property.totalFlatRent}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="bg-white rounded-2 my-1">
                    <div className="row align-items-center">
                      <div className="col-7 pricingLabel"> Rent Negotiable</div>
                      <div className="col-1">:</div>
                      <div className="valueTextForDetail fw-bold col-4">
                        {property.isNegotiable ? "Yes" : "No"}
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded-2 my-1">
                    <div className="row align-items-center">
                      <div className="col-7 pricingLabel">
                        {" "}
                        Deposit (in months)
                      </div>
                      <div className="col-1">:</div>
                      <div className="valueTextForDetail fw-bold col-4">
                        {property.deposit} rent
                      </div>
                    </div>
                  </div>

                  {/* <div className="bg-white rounded-2 my-2">
                    <div className="row align-items-center">
                      <div className="col-7 pricingLabel"> Additional Cost</div>
                      <div className="col-1">:</div>
                      <div className="valueTextForDetail fw-bold col-4">
                        {" "}
                        {property.additionalCosts.cost1 ? "Yes" : "No"}
                      </div>
                    </div>
                    {property.additionalCosts.description1 &&
                      property.additionalCosts.cost1 && (
                        <div className="row align-items-center">
                          <div className="col-7  pricingLabel">
                            {" "}
                            {property.additionalCosts.description1}
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail fw-bold col-4">
                            {" "}
                            {property.additionalCosts.cost1}
                          </div>
                        </div>
                      )}
                    {property.additionalCosts.description2 &&
                      property.additionalCosts.cost2 && (
                        <div className="row align-items-center">
                          <div className="col-7  pricingLabel">
                            {" "}
                            {property.additionalCosts.description2}
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail fw-bold col-4">
                            {" "}
                            {property.additionalCosts.cost2}
                          </div>
                        </div>
                      )}
                    {property.additionalCosts.description3 &&
                      property.additionalCosts.cost3 && (
                        <div className="row align-items-center">
                          <div className="col-7  pricingLabel">
                            {" "}
                            {property.additionalCosts.description3}
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail fw-bold col-4">
                            {" "}
                            {property.additionalCosts.cost3}
                          </div>
                        </div>
                      )}
                  </div> */}
                </div>
              </div>
            </div>
            <div className="detailsBox">
              <div className="boxHeader"> Contact Owner :</div>
              <div className="innerDetailsBox">
                <div className="labelTextForDetail">Owner:</div>
                <div className="fs-6">{property.name}</div>
                <div className="d-flex flex-column">
                  {/* <Button
                    className="rounded-pill"
                    id="contactOwner-pp"
                    onClick={() => {
                      contactOwner(property.id, property.type);
                    }}
                  >
                    {user && property.phone !== "0" ? (
                      <a href={`tel:${property.phone}`}>
                        <FontAwesomeIcon icon={faPhone} /> {property.phone}
                      </a>
                    ) : (
                      "Contact Owner"
                    )}
                  </Button> */}

                  <Button
                    className="rounded-pill"
                    id="contact-owner"
                    onClick={() => {
                      if (property.callAllowed) {
                        contactOwner(property.id, property.type);
                      } else {
                        downloadlink();
                      }
                    }}
                  >
                    {user && property.callAllowed ? (
                      property.phone !== "0" ? (
                        <a
                          href={`tel:${property.phone}`}
                          style={{ textDecoration: "none" }}
                        >
                          <FontAwesomeIcon icon={faPhone} /> {property.phone}
                        </a>
                      ) : (
                        "Contact Owner"
                      )
                    ) : (
                      "Contact Owner"
                    )}
                  </Button>

                  <Button
                    className="rounded-pill"
                    id="sendMessage-pp"
                    onClick={downloadlink}
                  >
                    Send Message
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="similarPropertyHeading">
          <Signup isOpen={signinmodal} onClose={signinModal} />
          {/* <LoginModal isOpen={loginmodal} onClose={loginModal} /> */}
          {/* <CustomPopup
            isOpen={customPopupmodal}
            data={customePopupData}
            onClose={customPopupModal}
          /> */}
          {/* <CustomWhatsappPopup
            isOpen={customWhatsappPopupmodal}
            data={customePopupData}
            onClose={() => customwhatsappPopupModal(property.phone)}
          /> */}
          <ExpirePopup isOpen={expirePopupmodal} onClose={ExpirePopupModal} />
          <Downloads
            isOpen={downloadmodel}
            onClose={() => {
              setdownloadmodel(false);
            }}
          />

          <h3 className="mt-5">Similar Properties :</h3>
        </div>
        <div className="featuresRow">
          {properties.map(
            (property, index) =>
              index < 5 && (
                <div className="card propertyCard " key={index}>
                  <Link
                    to={`/particular-property/${property.id}`}
                    className="text-decoration-none text-dark phone-width"
                  >
                    {property.photoUrls && property.photoUrls.length > 0 ? (
                      property.photoUrls[0].endsWith(".mp4") ? (
                        <video width="300" height="156" controls>
                          <source
                            src={property.photoUrls[0]}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <Image
                          src={property.photoUrls[0]}
                          className="card-img-top"
                          width="300px"
                          height="156px"
                          alt="..."
                        />
                      )
                    ) : (
                      <p>No media available</p>
                    )}

                    <div className="card-body" id="custom-title">
                      <h6 className="card-title ">
                        {" "}
                        <b>
                          {property.title.length > 70
                            ? `${property.title.substring(0, 70)}.`
                            : property.title}
                        </b>
                      </h6>
                      <p className="card-text">
                        <FontAwesomeIcon
                          className="me-2 grey"
                          icon={faLocationDot}
                        />{" "}
                        {property.address.area &&
                          property.address.area.charAt(0).toUpperCase() +
                            property.address.area.slice(1)}
                      </p>
                      <p className="card-text text-bold">
                        {property.type === "private flat"
                          ? `₹ ${property.totalFlatRent}`
                          : property.sharingType
                          ? property.sharingType.singleRent
                            ? `₹ ${property.sharingType.singleRent}`
                            : property.sharingType.doubleRent
                            ? `₹ ${property.sharingType.doubleRent}`
                            : property.sharingType.tripleRent
                            ? `₹ ${property.sharingType.tripleRent}`
                            : `₹ ${property.rentEachHead}`
                          : `₹ ${property.rentEachHead}`}{" "}
                        |{" "}
                        {property.furnishedType === "fully-furnished"
                          ? "Fully-Furnished"
                          : property.furnishedType === "unfurnished"
                          ? "Unfurnished"
                          : property.furnishedType === "semi-furnished"
                          ? "Semi-Furnished"
                          : property.furnishedType}
                      </p>
                    </div>
                  </Link>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default ParticularProperty;
