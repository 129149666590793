import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  faAnglesRight,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

const Autocompleteare = (props) => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");

  const handleChange = (address) => {
    setAddress(address);
  };

  // const handleSelect = async (address) => {
  //   const add = await geocodeByAddress(address);
  //   // console.log(add);
  //   setAddress(add[0].address_components[0].long_name);
  //   navigate(
  //     `/allproperties?location=${add[0].address_components[0].long_name}&budget=&gender=&furnished=&availability=&occupancy=`
  //   );
  // };

  const handleSelect = async (address) => {
    const add = await geocodeByAddress(address);
    const component = add[0].address_components.find(
      (component) =>
        component.types.includes("locality") ||
        component.types.includes("sublocality")
    );
    if (component) {
      setAddress(component.long_name);
      navigate(
        `/allproperties?location=${component.long_name}&budget=&gender=&furnished=&availability=&occupancy=`
      );
    }
  };

  return (
    <div className="d-flex justify-items-start">
      <div className="custom-search-bar  rounded-pill">
        <div className="search-icon">
          <FontAwesomeIcon icon={faLocationDot} />
        </div>

        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
          className="searchInputa"
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div style={{ width: "100%" }}>
              <div className=" homesearch" style={{ display: "flex" }}>
                <input
                  style={{ border: "none!important" }}
                  {...getInputProps({
                    placeholder: "Search Area ...",
                    className: "location-search-input ",
                  })}
                />
                <Link
                  to={`/allproperties?location=${address}&budget=&gender=&furnished=&availability=&occupancy=`}
                >
                  <button className="search-button rounded-pill d-flex">
                    Search{" "}
                    <FontAwesomeIcon
                      id="FontAwesomeIcon"
                      className="ms-2"
                      icon={faAnglesRight}
                    />{" "}
                  </button>
                </Link>

                {/* <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span
                          to={`/allproperties?location=${address}&budget=&gender=&furnished=&availability=&occupancy=`}
                        >
                          {suggestion.description}
                        </span>
                      </div>
                    );
                  })}
                </div> */}
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions
                    .filter(
                      (suggestion) =>
                        suggestion.types.includes("locality") ||
                        suggestion.types.includes("sublocality")
                    )
                    .map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    </div>
  );
};

export default Autocompleteare;
