import {} from "@fortawesome/free-regular-svg-icons";
import {
  faCircleQuestion,
  faCrown,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { ButtonToolbar, OverlayTrigger, Popover } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PLANS } from "../constants/plan";
import usePlan from "../contexts/planContext";
import { Link } from "react-router-dom";
import "./Premium.css";
const Premium = () => {
  const { setPlan } = usePlan();
  const router = useNavigate();
  const [activePlan, setActivePlan] = useState("king-plan");
  const scrollToPlan = (planId) => {
    setActivePlan(planId);
    const container = planContainerRef.current;

    if (container) {
      const oneThirdWidth = container.scrollWidth / 3;
      if (planId === "basic-plan") {
        container.scrollLeft = 0;
      } else if (planId === "king-plan") {
        container.scrollLeft = oneThirdWidth;
      } else {
        container.scrollLeft = 2 * oneThirdWidth;
      }
    }
  };
  const planContainerRef = React.createRef();
  useEffect(() => {
    planContainerRef.current.scrollLeft = 300;
    //eslint-disable-next-line
  }, []);
  const popoverHoverFocus = (message) => (
    <Popover
      id="popover-trigger-hover-focus"
      title="Popover bottom"
      className="p-1"
    >
      {message}
    </Popover>
  );

  const planChangeHandler = (plan) => {
    setPlan(plan);
    router("/checkout");
  };
  return (
    <div className="MainContainer">
      <div className="row no-gutters align-items-center justify-content-center">
        <div className="col-lg-6 col-xl-6 col-xxl-6 col-12 col-md-8">
          <div className="mb-5">
            <h2 className="text-md-start text-justify1 premium-introHeading">
              End Your Home Search Struggle, Just a Click Away
            </h2>
            <p className="text-md-start text-justify1 premium-introText">
              Save <span style={{ color: "#00B090" }}>100%</span> Brokerage Fees
            </p>
            <div
              className="d-md-none col-lg-8 col-xl-8 col-xxl-8 col-12 col-md-6 mt-5"
              id="premium-image"
            >
              <Image
                src="./emenities/premium-intro.png"
                alt="Premimum Details"
              />
            </div>
            <div className="d-flex mx-2 flex-column justify-items-start ms-5">
              <p className="fw-normal col-md-10 my-0 mb-3" id="">
                <svg
                  width="20"
                  height="13"
                  viewBox="0 0 20 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                    fill="#00B090"
                  />
                </svg>{" "}
                Direct Owners Contact
              </p>
              <p className="fw-normal col-md-10 my-0 mb-3" id="">
                <svg
                  width="20"
                  height="13"
                  viewBox="0 0 20 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                    fill="#00B090"
                  />
                </svg>{" "}
                No Brokers/Brokerage
              </p>
              <p className="fw-normal col-md-10 my-0 mb-3" id="">
                <svg
                  width="20"
                  height="13"
                  viewBox="0 0 20 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                    fill="#00B090"
                  />
                </svg>{" "}
                Quick and Easy Access
              </p>
              <p className="fw-normal col-md-10 my-0 mb-3" id="">
                <svg
                  width="20"
                  height="13"
                  viewBox="0 0 20 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                    fill="#00B090"
                  />
                </svg>{" "}
                24*7 Team Support{" "}
              </p>
              <div className="p-flex justify-items-start">
                {/* <Link
                  style={{ textDecoration: "none" }}
                  className="btn-own2"
                  to={"plan-detail"}
                > */}
                <a
                  href="#plan-detail"
                  style={{ textDecoration: "none" }}
                  className="btn-own2"
                >
                  See Plans
                </a>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-none d-md-block col-lg-4 col-xl-4 col-xxl-4 col-12 col-md-4"
          id="premium-image"
        >
          <Image src="./emenities/premium-intro.png" alt="Premimum Details" />
        </div>
      </div>
      <div className="premiumplan-heading" id="plan-detail">
        <h5 className="text-center premiumplan-title">Choose Your Plan</h5>
      </div>
      <div className="mainPremiumBox" ref={planContainerRef}>
        <div
          className={`plan PremiumCard ${
            activePlan === "basic-plan" ? "active" : ""
          }`}
          id="basic-plan"
        >
          <div className="freePlanBox">
            <div className="cardHeading">Basic Plan</div>
            <div className="cardDescription text-center">
              Trial plan to kickstart your search.
            </div>
            <div className="priceBox">
              <span style={{ fontSize: "25px" }}>₹</span>
              <span>99</span>
            </div>
            <div className="speapretor"></div>
            <div className="cardFeatures">
              <div className="featureTitle">Benefits:</div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Contact : only 3</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "With the Basic Plan, you can connect with up to 3 property owners, allowing you to explore different housing options with ease."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              {/* <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Sharing Flat</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Access listings for shared apartments, making it convenient for those looking to share living spaces."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div> */}
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    Daily new listings and updates
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Access listings for shared apartments, making it convenient for those looking to share living spaces."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              {/* <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">P.G.</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Find the most suitable Paying Guest accommodations to meet your requirements."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div> */}
              {/* <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Private Flat</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Explore listings for private flats, giving you more privacy and personal space."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div> */}
              {/* <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    Boost Listing for 5 Days
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus("Boost Listing for 7 Days")}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div> */}
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    Validity :1 Days only
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Your subscription is valid for 1 days from the date of purchase, giving you ample time to find your ideal home."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Limited Access</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus("Limited Access")}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#ffcd35",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="m11.807 16.415-1.395 1.392a.654.654 0 0 1-.927 0L6 14.324l-3.483 3.483a.657.657 0 0 1-.93 0L.193 16.415a.657.657 0 0 1 0-.93L3.675 12 .193 8.517a.662.662 0 0 1 0-.93l1.394-1.394a.657.657 0 0 1 .93 0L6 9.678l3.485-3.485a.654.654 0 0 1 .927 0l1.395 1.392a.662.662 0 0 1 .002.932L8.324 12l3.483 3.485a.657.657 0 0 1 0 .93Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    No refill (No coin refill in this plan)
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "No refill ( No coins refill in this plan )"
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#ffcd35",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="m11.807 16.415-1.395 1.392a.654.654 0 0 1-.927 0L6 14.324l-3.483 3.483a.657.657 0 0 1-.93 0L.193 16.415a.657.657 0 0 1 0-.93L3.675 12 .193 8.517a.662.662 0 0 1 0-.93l1.394-1.394a.657.657 0 0 1 .93 0L6 9.678l3.485-3.485a.654.654 0 0 1 .927 0l1.395 1.392a.662.662 0 0 1 .002.932L8.324 12l3.483 3.485a.657.657 0 0 1 0 .93Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Priority Support</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus("No priority support")}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#ffcd35",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="m11.807 16.415-1.395 1.392a.654.654 0 0 1-.927 0L6 14.324l-3.483 3.483a.657.657 0 0 1-.93 0L.193 16.415a.657.657 0 0 1 0-.93L3.675 12 .193 8.517a.662.662 0 0 1 0-.93l1.394-1.394a.657.657 0 0 1 .93 0L6 9.678l3.485-3.485a.654.654 0 0 1 .927 0l1.395 1.392a.662.662 0 0 1 .002.932L8.324 12l3.483 3.485a.657.657 0 0 1 0 .93Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Not refundable</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Please note that the Basic Plan subscription fee is non-refundable."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
            </div>
            <button
              className="continueButton"
              onClick={() => planChangeHandler(PLANS.BASIC)}
            >
              Continue
            </button>
          </div>
        </div>
        <div
          className={`plan PremiumCard ${
            activePlan === "king-plan" ? "active" : ""
          }`}
          id="king-plan"
        >
          <div className="badgeBox">
            <div className="popularBadge">Most Popular</div>
            <div className="downArrow">
              <FontAwesomeIcon icon={faSortDown} />
            </div>
          </div>
          <div className="basicPlanBox" id="kingPlanBoxId">
            <FontAwesomeIcon
              className="crownSection"
              style={{ pointerEvents: "none", color: "#ffc107" }}
              icon={faCrown}
            />
            <div className="cardHeading">Plus Plan</div>
            <div className="cardDescription text-center">
              Unlock more options and flexibility for the best results & search
              experience
            </div>
            <div className="priceBox">
              <span style={{ fontSize: "25px" }}>₹</span>
              <span>249</span>
            </div>
            <div className="speapretor"></div>
            <div className="cardFeatures">
              <div className="featureTitle" style={{ color: "#ffc107" }}>
                Benefits:
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Contact : 15 Daily</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Connect with up to 15 property owners daily to increase your chances of finding the perfect home."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    Daily new listings and updates
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Access listings for shared apartments, making it convenient for those looking to share living spaces."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              {/* <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">P.G.</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Find the most suitable Paying Guest accommodations to meet your requirements."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div> */}
              {/* <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Private Flat</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Explore listings for private flats, giving you more privacy and personal space."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div> */}

              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Validity :7 Days</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Your subscription is valid for 7 days, giving you more time to explore your housing options."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Full access</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus("Full access")}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    Refill daily (daily coin refill for smooth excess)
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Refill daily (daily coin refill for smooth excess)"
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    See your ad shown higher in the search result for 7 days
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus("Boost Listing for 7 Days")}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    Let's all users contact you for free
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Let's all users contact you for free"
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="m11.807 16.415-1.395 1.392a.654.654 0 0 1-.927 0L6 14.324l-3.483 3.483a.657.657 0 0 1-.93 0L.193 16.415a.657.657 0 0 1 0-.93L3.675 12 .193 8.517a.662.662 0 0 1 0-.93l1.394-1.394a.657.657 0 0 1 .93 0L6 9.678l3.485-3.485a.654.654 0 0 1 .927 0l1.395 1.392a.662.662 0 0 1 .002.932L8.324 12l3.483 3.485a.657.657 0 0 1 0 .93Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Priority Support</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus("No Priority Support")}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              {/* <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Personal assistant</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Benefit from a personal assistant to guide you through your property search journey."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div> */}

              {/* <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="m11.807 16.415-1.395 1.392a.654.654 0 0 1-.927 0L6 14.324l-3.483 3.483a.657.657 0 0 1-.93 0L.193 16.415a.657.657 0 0 1 0-.93L3.675 12 .193 8.517a.662.662 0 0 1 0-.93l1.394-1.394a.657.657 0 0 1 .93 0L6 9.678l3.485-3.485a.654.654 0 0 1 .927 0l1.395 1.392a.662.662 0 0 1 .002.932L8.324 12l3.483 3.485a.657.657 0 0 1 0 .93Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    Partially Refundable{" "}
                    <Link to={`/refund-policy`}>
                      <span style={{ fontSize: "12px" }}>(t&c appilied)</span>
                    </Link>
                  </div>
                  <div className="featureContent-text">Not refundable</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Please note that the King Plan subscription fee is non-refundable."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div> */}
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#fff",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="m11.807 16.415-1.395 1.392a.654.654 0 0 1-.927 0L6 14.324l-3.483 3.483a.657.657 0 0 1-.93 0L.193 16.415a.657.657 0 0 1 0-.93L3.675 12 .193 8.517a.662.662 0 0 1 0-.93l1.394-1.394a.657.657 0 0 1 .93 0L6 9.678l3.485-3.485a.654.654 0 0 1 .927 0l1.395 1.392a.662.662 0 0 1 .002.932L8.324 12l3.483 3.485a.657.657 0 0 1 0 .93Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Not refundable</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="left"
                    overlay={popoverHoverFocus(
                      "Please note that the King Plan subscription fee is non-refundable."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
            </div>
            <button
              className="continueButton"
              style={{ backgroundColor: "#ffc107" }}
              onClick={() => planChangeHandler(PLANS.KING)}
            >
              Continue
            </button>
          </div>
        </div>
        <div
          className={`plan PremiumCard ${
            activePlan === "pro-plan" ? "active" : ""
          }`}
          id="pro-plan"
        >
          <div className="proPlanBox" id="proPlanBoxId">
            <div className="cardHeading">PRO Plan</div>
            <div className="cardDescription text-center">
              Unlimited access and hassle free multiuse.{" "}
            </div>
            <div className="priceBox">
              <span style={{ fontSize: "25px" }}>₹</span>
              <span>499</span>
            </div>
            <div className="speapretor"></div>
            <div className="cardFeatures">
              <div className="featureTitle">Benefits:</div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">UNLIMITED ACCESS</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Connect with up to 50 property owners to maximize your property search efficiency."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    Daily new listings and updates
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Access listings for shared apartments, facilitating shared living arrangements."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              {/* <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">P.G.</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Find the most suitable Paying Guest accommodations to meet your requirements."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div> */}
              {/* <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Private Flat</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Explore listings for private flats, ensuring a higher level of privacy."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div> */}
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Validity :20 Days</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Your subscription is valid for a generous 20-day period, offering an extended search timeframe."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Full access</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus("Full access")}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    See your ad shown higher in the search result for 20 days
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus("Boost Listing for 20 Days")}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>

              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    Let's all users contact you for free
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Let's all users contact you for free"
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Priority Support</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Receive priority assistance from our support team to address your unique needs."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    Special Discount on renewal and future purchase
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Special Discount on renewal and future purchase"
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
              {/* <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#00b090",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">
                    50% Refundable{" "}
                    <Link to={`/refund-policy`}>
                      <span style={{ fontSize: "12px" }}>(t&c appilied)</span>
                    </Link>
                  </div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="left"
                    overlay={popoverHoverFocus(
                      "The PRO Plan provides a refund option, 50% Refunable in all the cases"
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div> */}
              <div className="featureContent">
                <div className="d-flex">
                  <svg
                    data-v-165629f9
                    data-v-9c16c3cc
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 24"
                    aria-label="check"
                    role="presentation"
                    className="h-icon-success"
                    style={{
                      width: "12px",
                      height: "24px",
                      fill: "#ffcd35",
                      marginRight: "8px",
                    }}
                  >
                    <g data-v-165629f9>
                      <path
                        data-v-165629f9
                        d="m11.807 16.415-1.395 1.392a.654.654 0 0 1-.927 0L6 14.324l-3.483 3.483a.657.657 0 0 1-.93 0L.193 16.415a.657.657 0 0 1 0-.93L3.675 12 .193 8.517a.662.662 0 0 1 0-.93l1.394-1.394a.657.657 0 0 1 .93 0L6 9.678l3.485-3.485a.654.654 0 0 1 .927 0l1.395 1.392a.662.662 0 0 1 .002.932L8.324 12l3.483 3.485a.657.657 0 0 1 0 .93Z"
                      ></path>
                    </g>
                  </svg>
                  <div className="featureContent-text">Not refundable</div>
                </div>
                <ButtonToolbar>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverHoverFocus(
                      "Please note that the PRO Plan subscription fee is non-refundable."
                    )}
                  >
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
            </div>
            <button
              className="continueButton"
              onClick={() => planChangeHandler(PLANS.PRO)}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      <div className="indicatorContainer LaptopHide">
        <div
          className={`indicator ${
            activePlan === "basic-plan" ? "activeIndicator" : ""
          }`}
          onClick={() => scrollToPlan("basic-plan")}
        ></div>
        <div
          className={`indicator ${
            activePlan === "king-plan" ? "activeIndicator" : ""
          }`}
          onClick={() => scrollToPlan("king-plan")}
        ></div>
        <div
          className={`indicator ${
            activePlan === "pro-plan" ? "activeIndicator" : ""
          }`}
          onClick={() => scrollToPlan("pro-plan")}
        ></div>
      </div>
      {/* testimonial */}
      <div className="premiumplan-heading">
        <h5 className="text-center premiumplan-title">
          What Our Customer Says
        </h5>
      </div>
      <div className="testimonial-group">
        <div className="client me-3">
          <div className="client-heading">
            <div>
              <Image
                style={{ borderRadius: "50%" }}
                src="./emenities/testimonial-1.jpeg"
                alt="Premium Details"
                height={"40px"}
                width={"40px"}
              />
            </div>

            <div className="name-intro ms-2">
              <h3>Yash Jain</h3>
              <p>Student at Nirma University</p>
            </div>
          </div>
          <div className="desription-box">
            <p className="client-description">
              King plan, basic price, but it did the job. Found my flat within a
              week. A great, straightforward service!
            </p>
            <div className="row justify-content-center">
              <div className="col-9">
                <Image src="./emenities/5-star.png" alt="Premimum Details" />
              </div>
              <div className="col-1">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="angle-right"
                  class="svg-inline--fa fa-angle-right "
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="client me-3">
          <div className="client-heading">
            <div>
              <Image
                style={{ borderRadius: "50%" }}
                src="./emenities/testimonial-2.jpeg"
                alt="Premium Details"
                height={"40px"}
                width={"40px"}
              />
            </div>

            <div className="name-intro ms-2">
              <h3>Suchit Seth</h3>
              <p>Softwere Engineer at Radiax</p>
            </div>
          </div>
          <div className="desription-box">
            <p className="client-description">
              They understands the bachelor struggle. No brokers, just the right
              properties. Impressed!
            </p>
            <div className="row justify-content-center">
              <div className="col-9">
                <Image src="./emenities/5-star.png" alt="Premimum Details" />
              </div>
              <div className="col-1">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="angle-right"
                  class="svg-inline--fa fa-angle-right "
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="client me-3">
          <div className="client-heading">
            <div>
              <Image
                style={{ borderRadius: "50%" }}
                src="./emenities/testimonial-3.jpeg"
                alt="Premium Details"
                height={"40px"}
                width={"40px"}
              />
            </div>

            <div className="name-intro ms-2">
              <h3>Diksha Sharma</h3>
              <p>Graphic Designer</p>
            </div>
          </div>
          <div className="desription-box">
            <p className="client-description">
              I was new in ahmedabad, and their team helped me in flat search. I
              found a flat that matched my requirements in just few calls.
            </p>
            <div className="row justify-content-center">
              <div className="col-9">
                <Image src="./emenities/5-star.png" alt="Premimum Details" />
              </div>
              <div className="col-1">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="angle-right"
                  class="svg-inline--fa fa-angle-right "
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="client me-3">
          <div className="client-heading">
            <div>
              <Image
                style={{ borderRadius: "50%" }}
                src="./emenities/testimonial-4.jpeg"
                alt="Premium Details"
                height={"40px"}
                width={"40px"}
              />
            </div>

            <div className="name-intro ms-2">
              <h3>Preksha Shah</h3>
              <p>Architect Intern</p>
            </div>
          </div>
          <div className="desription-box">
            <p className="client-description">
              Supportive team. After struggling for a week, came to know about
              these guys & they literally helped me in my flat search.
            </p>
            <div className="row justify-content-center">
              <div className="col-9">
                <Image src="./emenities/5-star.png" alt="Premimum Details" />
              </div>
              <div className="col-1">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="angle-right"
                  class="svg-inline--fa fa-angle-right "
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Premium;
