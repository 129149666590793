import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./navbar.css";
import LoginModal from "./Login.js";
import Signup from "./Signup";
import {
  faAngleRight,
  faAnglesDown,
  faBars,
  faCrown,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import "./demo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Button } from "reactstrap";
import CitySelectionModal from "./CitySelectionModal.js";
import axios from "axios";
const Navbar = ({ user }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginmodal, setLoginmodal] = useState(false);
  const [signinmodal, setSigninmodal] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const [showSubMenu, setShowSubMenu] = useState(false);
  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };
  const loginModal = () => {
    setSigninmodal(false);
    setLoginmodal(!loginmodal);
    handleShowNavbar();
  };
  const signinModal = () => {
    setLoginmodal(false);
    setSigninmodal(!signinmodal);
    handleShowNavbar();
  };
  const CITY_STORAGE_KEY = "active-city-data";
  const storedData = localStorage.getItem(CITY_STORAGE_KEY);
  const [showCityModal, setShowCityModal] = useState(false);
  const [city, setcity] = useState(localStorage.getItem("city"));
  const [storecity, setstorecity] = useState(
    localStorage.getItem("active-city-data")
  );
  useEffect(() => {
    const userId = localStorage.getItem("user");
    // console.log("navbar " + userId);
    // const userIdData = JSON.parse(localStorage.getItem("user"));
    // const userId = userIdData.id;

    // console.log(user.name);
    if (userId) {
      setIsLoggedIn(true);
    }
    const getcity = async () => {
      // Check for stored data first

      if (!storecity) {
        const apiResponse = await axios.get(
          `${process.env.REACT_APP_API_IP}/property/active-city`
        );
        localStorage.setItem(
          CITY_STORAGE_KEY,
          JSON.stringify(apiResponse.data)
        );
      }
      if (city == null) {
        setShowCityModal(true);
      }

      // Fallback to API call if not found or invalid
    };
    getcity();
  }, [loginmodal]);
  const logout = () => {
    localStorage.removeItem("user");
    setIsLoggedIn(false);
    window.location.reload();
  };

  let allcity = localStorage.getItem("active-city-data");

  return (
    <nav className="navbar shadow-sm sticky-top ">
      `{" "}
      {city == null && (
        <div
          className={`city-selection-modal ${
            window.innerWidth <= 768
          } ? 'mobile-view' : ''}`}
        >
          <div className="city-selection-content">
            <div className="city-selection-header">
              <h2>Select a City</h2>
              <button
                onClick={() => {
                  setShowCityModal(false);
                }}
                className="close-modal"
              >
                {/* ✕ */}
              </button>
            </div>
            <div className="city-grid">
              {allcity &&
                JSON.parse(allcity).map((Button, INDEX) => {
                  return (
                    <div
                      key={INDEX}
                      className={`city-item ${
                        localStorage.getItem("city") === Button.cityName
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => {
                        localStorage.setItem("city", Button.cityName);
                        window.location.reload();
                      }}
                    >
                      <img
                        src={Button.imageUrl}
                        alt={Button.cityName}
                        className="city-image"
                      />
                      <p>{Button.cityName}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      <div className="whatsapp-button">
        <Link
          target="_blank"
          rel="noreferrer"
          to="https://api.whatsapp.com/send?phone=+919316066832&text=Hello%20there,%20I%20have%20a%20question%20for%20you."
          className=""
        >
          <div className="icon-flex">
            <p>Help</p>
            <FontAwesomeIcon className="whats-icon" icon={faWhatsapp} />
          </div>
        </Link>
      </div>
      <div className="Navcontainer">
        <div className="logo">
          <Link id="logoBox" className="navbar-brand" to="/">
            <img
              src="/emenities/logo.png"
              width={"500px"}
              alt="logo"
              className="mobileAndTab-hide"
            />
            <span className="navbar-text">citynect</span>
          </Link>
        </div>
        <div className="menu-icon rounded" onClick={handleShowNavbar}>
          {showNavbar ? (
            <FontAwesomeIcon icon={faX} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          {/* For Mobile View Only */}
          <ul>
            {isLoggedIn ? (
              <div>
                <li id="Myaccount" onClick={toggleSubMenu}>
                  {user && (
                    <span>
                      {/* {user.name} */}
                      {user.name.length > 10
                        ? `${user.name.substring(0, 10)}`
                        : user.name}
                      {"  "}
                    </span>
                  )}{" "}
                  {showSubMenu ? (
                    <FontAwesomeIcon className="ms-2" icon={faAnglesDown} />
                  ) : (
                    <FontAwesomeIcon className="ms-2" icon={faAngleRight} />
                  )}
                </li>
                {showSubMenu && (
                  <div id="MyaccountDiv">
                    {user && user.isPremium !== 0 && (
                      <li>
                        {" "}
                        <Link
                          to={`/myaccount/premiumdetails`}
                          onClick={handleShowNavbar}
                        >
                          Premium Details
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        onClick={handleShowNavbar}
                        to={`/myaccount/contactedproperty`}
                      >
                        Contacted Properties
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={handleShowNavbar}
                        to={`/myaccount/savedproperty`}
                      >
                        Saved Properties
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={handleShowNavbar}
                        to={`/myaccount/listedproperty`}
                      >
                        Listed Properties
                      </Link>
                    </li>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <li id="Myaccount">
                  <Button
                    id="registerBtn"
                    onClick={signinModal}
                    className="loginBtnMobileView py-1 mb-1 btn-primary rounded me-2"
                  >
                    Register
                  </Button>
                  <Button
                    id="loginBtn"
                    onClick={loginModal}
                    className="loginBtnMobileView py-1 mb-1 btn-primary rounded me-2"
                  >
                    Login
                  </Button>
                </li>
              </div>
            )}
            <li onClick={handleShowNavbar}>
              <NavLink to="/Paying-guest">PG</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <NavLink to="/Preoccupied-Flats">Preoccupied Flats</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <NavLink to="/Private-Flats">Private Flats</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <NavLink to="/flatseekers">Flat Seekers</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <NavLink to="https://blogs.citynect.in/">Blog</NavLink>
            </li>
            <li className="listProperty" onClick={handleShowNavbar}>
              <Link to="/ListProperty">
                List Property{" "}
                <span
                  className="badge rounded-pill text-bg-warning"
                  style={{ fontWeight: "lighter", paddingBottom: "2px" }}
                >
                  Free
                </span>
              </Link>
            </li>
            {isLoggedIn ? (
              <div className="rounded text-white text-center" id="premium">
                <Link
                  className="text-white"
                  onClick={handleShowNavbar}
                  to={"/premium"}
                >
                  {" "}
                  <span className="rounded-circle p-1">
                    <FontAwesomeIcon
                      icon={faCrown}
                      style={{ color: "#ffc107" }}
                    />
                  </span>{" "}
                  Premium
                </Link>
              </div>
            ) : null}
            {showSubMenu && (
              <li>
                <Link onClick={logout}>Logout</Link>
              </li>
            )}
          </ul>
        </div>
        {isLoggedIn ? (
          <div className="rightBox">
            <Link
              className="text-decoration-none text-white"
              onClick={handleShowNavbar}
              to={"/premium"}
            >
              <Button className="py-2 mb-1 btn-dark rounded-pill me-2">
                {" "}
                <span className=" rounded-circle p-1">
                  <FontAwesomeIcon
                    icon={faCrown}
                    style={{ color: "#ffc107" }}
                  />
                </span>{" "}
                Premium
              </Button>
            </Link>
            <div className="dropdown">
              <span className="dropbtn py-2 mb-1 rounded-pill">
                {user && (
                  <span>
                    {/* {user.name} */}
                    {user.name.length > 10
                      ? `${user.name.substring(0, 10)}`
                      : user.name}
                    {"  "}
                  </span>
                )}{" "}
                <FontAwesomeIcon icon={faAnglesDown} />
              </span>
              <div className="dropdown-content">
                {/* {user && (
                  <Link to={``} onClick={handleShowNavbar}>
                    {user.name}
                  </Link>
                )} */}
                {user && user.isPremium !== 0 && (
                  <Link
                    to={`/myaccount/premiumdetails`}
                    onClick={handleShowNavbar}
                  >
                    Premium Details
                  </Link>
                )}
                <Link
                  to={`/myaccount/contactedproperty`}
                  onClick={handleShowNavbar}
                >
                  Contacted Properties
                </Link>
                <Link
                  to={`/myaccount/savedproperty`}
                  onClick={handleShowNavbar}
                >
                  Saved Properties
                </Link>
                <Link
                  to={`/myaccount/listedproperty`}
                  onClick={handleShowNavbar}
                >
                  Listed Properties
                </Link>
                <Link onClick={logout}>Logout</Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="rightBox">
            <Button
              onClick={loginModal}
              id="loginBtn"
              className="py-2 mb-1 btn-primary rounded me-2"
            >
              Login
            </Button>
            <Button
              onClick={signinModal}
              id="registerBtn"
              className="py-2 mb-1 btn-primary rounded me-2"
            >
              Register
            </Button>
          </div>
        )}
      </div>
      {/* Signin Modal */}
      <Signup isOpen={signinmodal} onClose={signinModal} />
      {/* Login Modal */}
      <LoginModal isOpen={loginmodal} onClose={loginModal} />
    </nav>
  );
};

export default Navbar;
